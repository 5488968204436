import { Link, useLocation } from 'react-router-dom';
import { css, styled } from 'styled-components';
import ThemeSelector from './ThemeSelector';
import { useEffect } from 'react';
import { retrieveSettings } from '../../utils/manage-auth-token';
import { useAppDispatch, useAppSelector } from '../../services/redux/hooks';
import { updateTheme } from '../../services/redux/reducers/app';
import { useGetProfileQuery } from '../../services/endpoints/profile';
import { useGlobalConfigurationQuery } from '../../services/endpoints/app-data';

const SidbarStyle = styled.aside`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;

	& .header,
	& .footer,
	& .links {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	& .header {
		display: flex;
		justify-content: center;
	}

	& .links {
		flex: 1;
	}

	& .profile-pill {
		display: block;
		text-decoration: none;
		display: flex;
		align-items: center;
		color: ${(p) => p.theme.text};
		padding: 10px;
		border: 1px solid ${(p) => p.theme.border};
		word-break: break-word;
		& img {
			width: 42px;
			height: 42px;
			border-radius: 50%;
			margin-right: 10px;
		}
	}
`;
const SideLinkStyle = styled(Link)<{ active: any }>`
	position: relative;
	display: flex;
	background-color: ${(p) => p.theme.backgroundOpaque};
	padding: 10px;
	font-size: 16px;
	font-weight: 600;
	align-items: center;
	text-decoration: none;
	border-radius: 7px;
	margin-bottom: 5px;

	& .icon {
		display: inline-block;
		margin-right: 10px;
		color: ${(p) => p.theme.link};
		font-size: 18px;
		font-weight: 600;
	}

	& .link-name {
		display: inline-block;
		color: #3d3fac;
		color: ${(p) => p.theme.sidebarLink};
	}

	& .count {
		position: absolute;
		right: 10%;
		top: calc(50% - 12px);
		background-color: ${(p) => p.theme.accent};
		color: ${(p) => p.theme.text};
		width: 18px;
		height: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		border-radius: 50%;
	}

	${(p) =>
		p.active &&
		css`
			background-color: ${(p) => p.theme.link};

			& .link-name {
				color: white;
			}

			& .icon {
				color: white;
			}
		`}
`;

export const SideLink = ({
	name,
	count,
	googleIconName,
	link,
}: {
	name: any;
	link: any;
	googleIconName: any;
	count?: number;
}) => {
	const { pathname } = useLocation();

	const isActive = () => {
		return !!(pathname === link);
	};
	return (
		<SideLinkStyle to={link} active={isActive() ? 'true' : undefined}>
			<span className="material-symbols-outlined icon">{googleIconName}</span>
			<span className="link-name">{name}</span>
			{count && <span className="count">{count}</span>}
		</SideLinkStyle>
	);
};

const Sidebar = () => {
	const dispatch = useAppDispatch();
	useGetProfileQuery({});
	useGlobalConfigurationQuery({});

	const { user } = useAppSelector((store) => store.auth);

	useEffect(() => {
		const settings = retrieveSettings();
		if (settings) {
			dispatch(updateTheme(settings.theme));
		}
	}, [dispatch]);

	return (
		<SidbarStyle>
			<div className="header">
				<Link to="/">
					<img src="/hammer-game-logo.png" alt="logo" width={60} />
				</Link>
			</div>
			<div className="links">
				<SideLink name="Dashboard" googleIconName="widgets" link="/" />
				<SideLink name="Merchant" googleIconName="domain" link="/merchant" />
				<SideLink
					name="Audit Trail"
					googleIconName="trail_length_medium"
					link="/audit-trail"
				/>
				<SideLink
					name="Marketplace"
					googleIconName="storefront"
					link="/marketplace"
				/>
				<SideLink
					name="Sys. Template"
					googleIconName="stacked_email"
					link="/email-templates"
				/>
				<SideLink name="Cron Jobs" googleIconName="robot" link="/cron-jobs" />
			</div>
			<div className="footer">
				<SideLink name="Settings" googleIconName="settings" link="/settings" />
				<SideLink
					name="Notification"
					googleIconName="notifications"
					link="/notifications"
					count={1}
				/>
				<ThemeSelector />
				<Link to="/profile" className="profile-pill">
					<div>
						<img src="/defaultone.png" alt="" />
					</div>
					<div>
						<p>{user.userEmail}</p>
						<p>@{user.userName}</p>
					</div>
				</Link>
			</div>
		</SidbarStyle>
	);
};

export default Sidebar;
