import { RouteObject } from 'react-router-dom';
import Login from '../components/auth/Login';
import CompleteSetup from '../components/auth/CompleteSetup';
import ResetPassword from '../components/auth/ResetPassword';

const authRoutes: RouteObject[] = [
	{
		path: 'login',
		element: <Login />,
	},
	{
		path: 'complete-setup',
		element: <CompleteSetup />,
	},
	{
		path: 'reset-password',
		element: <ResetPassword />,
	},
];

export default authRoutes;
