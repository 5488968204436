import { configureStore } from '@reduxjs/toolkit';
import hammerApi from '../api';
import combinedReducers from './reducers';
import cronApi from '../cron-api';

const store = configureStore({
	reducer: combinedReducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(hammerApi.middleware, cronApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
