import { Divider } from 'antd';
import { styled } from 'styled-components';

const ResetPassword = () => {
	return (
		<Style>
			<h1>Reset Password</h1>
			<Divider />
		</Style>
	);
};

export default ResetPassword;

const Style = styled.div`
	padding: 20px;
	margin: auto;
	width: 50%;
`;
