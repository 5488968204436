import { RouteObject } from 'react-router-dom';
import AuditTrail from '../components/audit/AuditTrail';

const auditTrailRoutes: RouteObject[] = [
	{
		path: '',
		element: <AuditTrail />,
	},
];

export default auditTrailRoutes;
