import { useMutation, useQuery } from 'react-query';
import {
	deleteAdminEmailProfile,
	// deleteSystemEmailAccessKey,
	getAdminEmailProfile,
	// getSystemEmailAccessKey,
	getSystemEmailConfig,
	postAdminEmailProfile,
	// postSystemEmailAccessKey,
} from '../../services/endpoints/email-setup';
import { Button, Divider, Input, Modal, message } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

function EmailProfile() {
	const [open, setOpen] = useState(false);
	const [state, setState] = useState({
		companyName: '',
		email: '',
		password: '',
	});

	const { data, refetch: refetchProfile } = useQuery(
		'admin-email-profile',
		getAdminEmailProfile,
		{ retry: false }
	);
	const { data: ddd } = useQuery('email-config', getSystemEmailConfig, {
		retry: false,
	});
	console.log(ddd?.status);

	const { isLoading, mutate: createProfile } = useMutation(
		postAdminEmailProfile,
		{
			onSuccess: () => {
				message.success('Account Created Successfully');
				setOpen(false);
				refetchProfile();
			},
		}
	);

	const isProfileCreated = () => {
		return Object.keys(data?.data || {}).length === 0;
	};

	return (
		<div>
			{isProfileCreated() ? (
				<Button onClick={() => setOpen(true)}>
					Create Admin Email Profile
				</Button>
			) : (
				<DisplayEmailProfile data={data?.data} />
			)}

			<Modal
				title="Create Admin Account"
				open={open}
				confirmLoading={isLoading}
				onCancel={() => setOpen(false)}
				footer={null}
			>
				<div>
					<p>Create an account on Xend Email</p>
				</div>
				<Divider />
				<div>
					<p>Company Name</p>
					<Input
						size="large"
						placeholder="Company Name"
						value={state.companyName}
						onChange={(e) =>
							setState({ ...state, companyName: e.target.value })
						}
					/>
				</div>
				<br />
				<div>
					<p>Email</p>
					<Input
						size="large"
						placeholder="Email"
						value={state.email}
						onChange={(e) => setState({ ...state, email: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<p>Password</p>
					<Input.Password
						size="large"
						placeholder="Password"
						value={state.password}
						onChange={(e) => setState({ ...state, password: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<Button
						block
						size="large"
						type="primary"
						onClick={() => createProfile(state)}
					>
						Submit
					</Button>
				</div>
			</Modal>
		</div>
	);
}

export default EmailProfile;

const DisplayEmailProfile = ({ data }: { data: any }) => {
	const [open, setOpen] = useState(false);

	const { mutate: deleteProfile } = useMutation(deleteAdminEmailProfile, {
		onSuccess: () => {
			message.success('Deleted Successfully');
		},
	});

	const handleDeleteProfile = () => {
		Modal.confirm({
			title: 'Are you sure?',
			content:
				'This action is irreversible. Make sure you know what you are doing.',
			onOk: () => {
				deleteProfile();
			},
		});
	};

	return (
		<ProfileStyle>
			<div className="details">
				<div>
					<p className="label">Company Name</p>
					<p>{data?.companyName}</p>
				</div>
				<div>
					<p className="label">Email</p>
					<p>{data?.email}</p>
				</div>
				<div>
					<p className="label">Verified Profile</p>
					<p>{data?.isVerified ? 'Verified' : 'Not Verified'}</p>
				</div>
				<div>
					<p className="label">Updated</p>
					<p>{moment(data?.updatedAt).format('lll')}</p>
				</div>
			</div>
			<div>
				<Button onClick={() => setOpen(true)} size="small">
					Manage
				</Button>
				<Modal
					open={open}
					title="Manage Profile"
					width={320}
					onCancel={() => setOpen(false)}
					footer={null}
				>
					<Divider />
					<br />
					<div>
						<Button onClick={handleDeleteProfile} block danger type="primary">
							Delete Profile
						</Button>
					</div>
					<br />
					<br />
				</Modal>
			</div>

			<br />

			{/*<ManageClientAccessKey />*/}
		</ProfileStyle>
	);
};

const ProfileStyle = styled.div`
	& .details {
		display: flex;
		margin-bottom: 10px;
		& > div {
			margin-right: 20px;
			& .label {
				color: #6f6f6f;
			}
		}
	}
`;

// const ManageClientAccessKey = () => {
// 	const { data: clientAccessKey, refetch: refetchClientAccessKey } = useQuery(
// 		'system-accesskey',
// 		getSystemEmailAccessKey
// 	);
//
// 	const [clientName, setClientName] = useState('');
// 	const [open, setOpen] = useState(false);
//
// 	const { isLoading: creatingKey, mutate: createKey } = useMutation(
// 		postSystemEmailAccessKey,
// 		{
// 			onSuccess: () => {
// 				message.success('Client Key Created Successfull');
// 				setOpen(false);
// 				refetchClientAccessKey();
// 			},
// 		}
// 	);
//
// 	const isClientAccessKeyCreated = () => {
// 		return Object.keys(clientAccessKey?.data || {}).length === 0;
// 	};
//
// 	const { mutate: deleteKey } = useMutation(deleteSystemEmailAccessKey, {
// 		onSuccess: () => {
// 			message.success('Key Deleted!');
// 			refetchClientAccessKey();
// 		},
// 	});
//
// 	const handleDeleteClientName = () => {
// 		Modal.confirm({
// 			content:
// 				'This action is irrevesible and it would stop the sending of emails. Make sure you know what you are doing before you continue.',
// 			type: 'warning',
// 			title: 'Confirm Action',
// 			onOk: () => {
// 				deleteKey();
// 			},
// 		});
// 	};
//
// 	return (
// 		<div>
// 			{isClientAccessKeyCreated() ? (
// 				<Button onClick={() => setOpen(true)}>Create Access Key</Button>
// 			) : (
// 				<div>
// 					<Typography.Text type="success">
// 						Client access key created [
// 						<strong>{clientAccessKey?.data?.clientName}</strong>]
// 					</Typography.Text>
// 					<br />
// 					<div>
// 						<Button
// 							danger
// 							size="small"
// 							type="default"
// 							onClick={handleDeleteClientName}
// 						>
// 							Delete Client Name
// 						</Button>
// 					</div>
// 				</div>
// 			)}
// 			<Modal
// 				confirmLoading={creatingKey}
// 				title="Create Client Access"
// 				open={open}
// 				onCancel={() => setOpen(false)}
// 				width={320}
// 				onOk={() => createKey(clientName)}
// 			>
// 				<Divider />
// 				<p>
// 					Create a Client Name to enable admin to send emails using Xend Email.
// 				</p>
// 				<br />
// 				<div>
// 					<p>Client Name</p>
// 					<Input
// 						size="large"
// 						placeholder="Client Name"
// 						value={clientName}
// 						onChange={(e) => setClientName(e.target.value)}
// 					/>
// 				</div>
// 			</Modal>
// 		</div>
// 	);
// };
