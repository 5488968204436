import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import authRoutes from './routes/auth-routes';
import dashboardRoutes from './routes/dashboard-routes';
import MainLayout from './components/layouts/Main';
import UnAuthenticatedLayout from './components/layouts/UnAuthenticated';
import { ThemeProvider } from 'styled-components';
import { darkMode, lightMode } from './utils/theme';
import { useAppSelector } from './services/redux/hooks';
import GlobalStyles from './GlobalStyle';
import ErrorPage from './components/layouts/ErrorPage';
import settingsRoutes from './routes/settings-routes';
import merchantRoutes from './routes/merchant-routes';
import { ConfigProvider, theme as antdTheme } from 'antd';
import auditTrailRoutes from './routes/audit-trail-routes';
import marketplaceRoutes from './routes/market-place-routes';
import Notifications from './components/notifications/Notifications';
import emailTemplateRoutes from './routes/email-templates';

const router = createBrowserRouter([
	{
		path: '/auth',
		element: <UnAuthenticatedLayout />,
		children: authRoutes,
	},
	{
		path: '/settings',
		element: <MainLayout />,
		children: settingsRoutes,
		errorElement: <ErrorPage />,
	},
	{
		path: '/merchant',
		element: <MainLayout />,
		children: merchantRoutes,
		errorElement: <ErrorPage />,
	},
	{
		path: '/audit-trail',
		element: <MainLayout />,
		children: auditTrailRoutes,
		errorElement: <ErrorPage />,
	},
	{
		path: '/marketplace',
		element: <MainLayout />,
		children: marketplaceRoutes,
		errorElement: <ErrorPage />,
	},
	{
		path: '/notifications',
		element: <MainLayout />,
		children: [{ path: '', element: <Notifications /> }],
		errorElement: <ErrorPage />,
	},
	{
		path: '/email-templates',
		element: <MainLayout />,
		children: emailTemplateRoutes,
		errorElement: <ErrorPage />,
	},
	{
		path: '/',
		element: <MainLayout />,
		children: dashboardRoutes,
		errorElement: <ErrorPage />,
	},
]);

function App() {
	const { theme } = useAppSelector((store) => store.app);

	return (
		<ThemeProvider theme={theme === 'dark' ? darkMode : lightMode}>
			<ConfigProvider
				theme={{
					token: {
						colorPrimary: '#84c70d',
					},
					algorithm:
						theme === 'dark'
							? antdTheme.darkAlgorithm
							: antdTheme.defaultAlgorithm,
				}}
			>
				<GlobalStyles />
				<RouterProvider router={router} />
			</ConfigProvider>
		</ThemeProvider>
	);
}

export default App;
