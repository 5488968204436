const extractReplacementPlaceholders = (html: any) => {
	const regex = /{{(.*?)}}/g;
	const matches = [];
	let match;

	while ((match = regex.exec(html))) {
		matches.push(match[1].trim());
	}

	console.log(matches);

	return matches;
};

export default extractReplacementPlaceholders;
