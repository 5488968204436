import { styled } from 'styled-components';
import { useGetMerchantsQuery } from '../../services/endpoints/merchant';
import Table from '../layouts/Table';
import InfoCard from '../layouts/InfoCard';
import { Link } from 'react-router-dom';
import { darkMode } from '../../utils/theme';
import moment from 'moment';

const MerchantsOverview = () => {
	const { data } = useGetMerchantsQuery({});

	return (
		<Style>
			<div>
				{/* <InfoCard
					title="Active Merchants"
					value={data?.data.paginator.itemCount}
				/> */}
				<InfoCard
					title="Total Merchants"
					value={data?.data.paginator.itemCount}
				/>
				<Table
					dataSource={data?.data.itemsList || []}
					fields={[
						{
							header: 'Merchant',
							cell: ({ row }) => (
								<span>
									{row.original.companyName}
									{row.original.isVerified && (
										<span
											style={{
												fontSize: 14,
												color: darkMode.accent,
											}}
											className="material-symbols-outlined"
										>
											new_releases
										</span>
									)}
								</span>
							),
						},
						{
							header: 'Domain',
							accessorFn: (row) => row.domainName,
						},
						{
							header: 'Created',
							accessorFn: (row) => moment(row.createdAt).format('ll'),
						},
						{
							header: '',
							id: '_id',
							cell: ({ row }) => (
								<Link to={`/merchant/single/${row.original._id}`}>
									<span className="material-symbols-outlined">open_in_new</span>
								</Link>
							),
						},
					]}
					totalItems={data?.data.paginator.itemCount}
				/>
			</div>
		</Style>
	);
};

export default MerchantsOverview;

const Style = styled.div``;
