import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

interface IPageWrapper {
	title?: any;
	children?: ReactNode;
}

const PageWrapper = ({ title, children }: IPageWrapper) => {
	return (
		<>
			<Helmet>
				<title>{`${title} ☆ Hammer Games`}</title>
			</Helmet>
			{children}
		</>
	);
};

export default PageWrapper;
