import { Outlet, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import Sidebar from './Sidebar';
import { isAppAuthenticated } from '../../utils/manage-auth-token';
import { useEffect } from 'react';
import EnableTwoFactorNotice from './EnableTwoFactorNotice';

const MainLayoutStyle = styled.main`
	position: fixed;
	width: 100%;
	height: 100%;
	background: ${(p) => p.theme.background};
	display: grid;
	grid-template-columns: 1fr;

	& .sidebar {
		padding: 20px;
		display: none;
	}

	& .content {
		padding: 20px;
		overflow-y: auto;
	}

	@media (min-width: 768px) {
		grid-template-columns: 240px 1fr;

		& .sidebar {
			display: block;
			border-right: 1px solid ${(p) => p.theme.border};
		}
	}
`;

const MainLayout = () => {
	const navigate = useNavigate();

	useEffect(() => {
		if (!isAppAuthenticated()) {
			navigate('/auth/login');
		}
	}, [navigate]);

	if (isAppAuthenticated()) {
		return (
			<>
				<EnableTwoFactorNotice />
				<MainLayoutStyle>
					<section className="sidebar">
						<Sidebar />
					</section>
					<section className="content">
						<Outlet />
					</section>
				</MainLayoutStyle>
			</>
		);
	}

	return null;
};

export default MainLayout;
