import cronApi, { cronBaseuri } from '../cron-api';
import { PaginatedResponse, ResponseStructure } from './response-interfaces';

const cronjobService = cronApi.injectEndpoints({
	endpoints: (build) => ({
		getCronjobs: build.query<PaginatedResponse, any>({
			query: (params) => ({
				url: '/v1/app-cron',
				params,
			}),
		}),
		addCronjob: build.mutation<
			ResponseStructure,
			{ title: any; url: any; interval: any; subInterval?: any }
		>({
			query: (body) => ({
				url: '/v1/app-cron',
				method: 'POST',
				body,
			}),
		}),
		deleteCronjob: build.mutation<ResponseStructure, { id: any }>({
			query: ({ id }) => ({
				url: `/v1/app-cron/${id}`,
				method: 'DELETE',
			}),
		}),
		cronjobTimes: build.query({
			query: () => ({
				url: '/v1/app-cron/cronjob-time',
			}),
		}),
		triggerCronJob: build.mutation<ResponseStructure, { id: any }>({
			query: ({ id }) => ({
				url: '/v1/app-cron/trigger-job',
				method: 'POST',
				body: { id },
			}),
		}),
		updateCronJobStatus: build.mutation<
			ResponseStructure,
			{ id: any; status: any }
		>({
			query: ({ id, status }) => ({
				url: `/v1/app-cron/${id}/status`,
				method: 'PATCH',
				body: { status },
			}),
		}),
	}),
});

export const {
	useGetCronjobsQuery,
	useAddCronjobMutation,
	useDeleteCronjobMutation,
	useCronjobTimesQuery,
	useTriggerCronJobMutation,
	useUpdateCronJobStatusMutation,
} = cronjobService;

export default cronjobService;

export const getCronjobs = async (params: any) => {
	const res = await cronBaseuri.get('/v1/app-cron', { params });
	return res.data;
};
