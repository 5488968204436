const tokenName = '0-h-932-e';
const appSettings = '0-h-app-settings';

interface IAuthToken {
	jwt: string;
	accessSecret?: string;
}

interface IAppSettings {
	theme?: string;
}

export const retrieveToken = (): IAuthToken | null => {
	const data = localStorage.getItem(tokenName);
	if (data) {
		return JSON.parse(data);
	}
	return null;
};

export const saveToken = (data: IAuthToken) => {
	localStorage.setItem(tokenName, JSON.stringify(data));
};

export const deleteToken = () => {
	localStorage.removeItem(tokenName);
};

export const isAppAuthenticated = (): boolean => {
	const token = retrieveToken();
	return !!token;
};

export const retrieveSettings = (): IAppSettings | null => {
	const data = localStorage.getItem(appSettings);
	if (data) {
		return JSON.parse(data);
	}
	return null;
};

export const saveSettings = (data: IAppSettings) => {
	let a = {};
	const settings = localStorage.getItem(appSettings);
	if (settings) {
		a = JSON.parse(settings);
	}
	localStorage.setItem(appSettings, JSON.stringify({ ...a, ...data }));
};
