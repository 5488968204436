import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Modal, Popconfirm, Select } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Table from '../layouts/Table';
import {
	useAddAdminCustodialUserMutation,
	useDeleteAdminCustodialUserMutation,
	useGetAdminCustodialUsersQuery,
} from '../../services/endpoints/settings';
import moment from 'moment';

const CustodialUser = () => {
	const [addAdminUser, { isLoading, isSuccess, isError }] =
		useAddAdminCustodialUserMutation();
	const { data: custodialUsers } = useGetAdminCustodialUsersQuery({});

	const [open, setOpen] = useState(false);

	const formik = useFormik({
		initialValues: {
			email: '',
			userName: '',
			botType: 'PROFIT_SHARE',
		},
		onSubmit: (values) => {
			console.log(values);
			addAdminUser(values);
		},
	});

	useEffect(() => {
		if (isSuccess) {
			setOpen(false);
		}
	}, [isSuccess, isError]);

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ flex: 1 }}>
					<h2>Custodial User</h2>
					<p>
						This users hold value for different functionalities for the
						administrators like the profit shares from merchants.
					</p>
				</div>
				<div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
					<Button icon={<PlusOutlined />} onClick={() => setOpen(true)}>
						Custodial User
					</Button>
				</div>
			</div>
			<br />
			<Modal
				confirmLoading={isLoading}
				open={open}
				onCancel={() => setOpen(false)}
				okText="Add Account"
				onOk={() => formik.handleSubmit()}
				title="Add Custodial Account"
			>
				<Divider />
				<div>
					<label htmlFor="email">Email</label>
					<Input
						name="email"
						id="email"
						size="large"
						value={formik.values.email}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="">Username</label>
					<Input
						size="large"
						id="userName"
						name="userName"
						value={formik.values.userName}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="botType">Bot Type</label>
					<Select
						size="large"
						value={formik.values.botType}
						style={{ width: '100%' }}
						onChange={(e) => formik.setFieldError('botType', e)}
					>
						<Select.Option value="">--</Select.Option>
						<Select.Option value="PROFIT_SHARE">PROFIT_SHARE</Select.Option>
					</Select>
				</div>
			</Modal>

			<Table
				dataSource={custodialUsers?.data.docs || []}
				fields={[
					{
						header: 'Email',
						accessorFn: (row) => row.userEmail,
					},
					{
						header: 'Username',
						accessorFn: (row) => row.userName,
					},
					{
						header: 'Custodial Form',
						accessorFn: (row) => row.accountCustodialForm,
					},
					{
						header: 'Updated',
						accessorFn: (row) => moment(row.updatedAt).format('lll'),
					},
					{
						header: '',
						id: '_id',
						cell: ({ row }) => (
							<EditCustodialUser custodialUser={row.original} />
						),
					},
				]}
			/>
		</div>
	);
};

export default CustodialUser;

const EditCustodialUser = ({ custodialUser }: { custodialUser: any }) => {
	const [open, setOpen] = useState(false);

	const [removeCustodian] = useDeleteAdminCustodialUserMutation();

	const formik = useFormik({
		initialValues: {
			userEmail: '',
		},
		onSubmit: (values) => {
			console.log(values);
		},
	});

	useEffect(() => {
		if (custodialUser) {
			formik.setFieldValue('userEmail', custodialUser.userEmail);
		}
	}, [custodialUser]);

	return (
		<>
			<Button onClick={() => setOpen(true)} icon={<EditOutlined />}>
				Edit Custodian
			</Button>

			<Modal
				width={320}
				footer={null}
				open={open}
				onCancel={() => setOpen(false)}
				title="Edit Custodian"
				onOk={() => formik.handleSubmit()}
			>
				<Divider />
				<div>
					<label htmlFor="userEmail">Email</label>
					<Input size="large" value={formik.values.userEmail} readOnly />
				</div>
				<Divider />
				<Popconfirm
					onConfirm={() => removeCustodian({ accountId: custodialUser?._id })}
					title="Are you sure?"
				>
					<Button danger type="primary" block>
						Remove Custodian
					</Button>
				</Popconfirm>
			</Modal>
		</>
	);
};
