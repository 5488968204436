import { styled } from 'styled-components';
import PageWrapper from '../layouts/PageWrapper';
import Logout from './Logout';
import { useGetProfileQuery } from '../../services/endpoints/profile';
import { Avatar, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const AuthProfile = () => {
	const { data: profile } = useGetProfileQuery({});

	return (
		<PageWrapper title="My Profile">
			<Style>
				<div>
					<Avatar icon={<UserOutlined />} />
					<p>{profile?.data.userName}</p>
					<p>{profile?.data?.userEmail}</p>
					<p>{profile?.data?.userPhone}</p>
					<p>2FA Enabled</p>
					<p>{profile?.data?.enabledLoginTwoFactor ? 'Enabled' : 'Disabled'}</p>
				</div>

				<Divider />

				<Logout />
			</Style>
		</PageWrapper>
	);
};

export default AuthProfile;

const Style = styled.div``;
