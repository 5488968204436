import {
	Button,
	Divider,
	Input,
	Modal,
	Popconfirm,
	Select,
	Space,
	Switch,
	Table,
	message,
} from 'antd';
import ContractAddresses from './ContractAddresses';
import { useMutation } from 'react-query';
import {
	deleteBlockchainNetwork,
	getBlockchainNetworks,
	postBlockchainNetwork,
	putBlockchainNetwork,
} from '../../services/endpoints/currency';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';

function BlockchainNetworks() {
	const {
		mutate: getNetworks,
		data,
		isLoading,
	} = useMutation(getBlockchainNetworks);

	useEffect(() => {
		getNetworks();
	}, []);

	return (
		<div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginBottom: 20,
				}}
			>
				<p>Blockchain Networks</p>
				<AddBlockchainNetwork />
			</div>
			<Table
				loading={isLoading}
				columns={[
					{ title: 'Status', dataIndex: 'status' },
					{ title: 'Name', dataIndex: 'name' },
					{ title: 'Abbreviation', dataIndex: 'abbreviation' },
					{ title: 'Network id', dataIndex: 'networkId' },
					{ title: 'Native Token', dataIndex: 'nativeTokenSymbol' },
					{
						title: 'Native Token CoinGeckoId',
						dataIndex: 'nativeTokenCoinGeckoId',
					},
					{ title: 'Token Standard', dataIndex: 'tokenStandard' },
					{ title: 'SwitchWalletEnum', dataIndex: 'switchWalletEnum' },
					{
						title: 'Allow Withdrawal',
						dataIndex: 'allowWithdrawal',
						render: (value) => (value ? 'TRUE' : 'FALSE'),
					},
					{
						title: 'Last Updated',
						dataIndex: 'updatedAt',
						render: (value) => moment(value).format('lll'),
					},
					{
						title: '',
						dataIndex: '_id',
						render: (id, network) => (
							<>
								<EditBlockchainNetwork network={network} />
								<DeleteBlockchainNetwork id={id} />
							</>
						),
					},
				]}
				dataSource={data?.data?.itemsList || []}
			/>

			<Divider />

			<ContractAddresses />
		</div>
	);
}

export default BlockchainNetworks;

const AddBlockchainNetwork = () => {
	const [open, setOpen] = useState(false);

	const { mutate: postNetworks, isLoading } = useMutation(
		postBlockchainNetwork,
		{
			onSuccess: () => {
				message.success('Blockchain Network added!');
				setOpen(false);
			},
		}
	);

	const formik = useFormik({
		initialValues: {
			name: '',
			status: '',
			explorerUrl: '',
			iconUrl: '',
			abbreviation: '',
			networkId: '',
			nativeTokenSymbol: '',
			nativeTokenCoinGeckoId: '',
			tokenStandard: '',
			switchWalletEnum: '',
			allowWithdrawal: false,
		},
		onSubmit: (values) => {
			postNetworks(values);
		},
	});

	return (
		<>
			<Button icon={<PlusOutlined />} onClick={() => setOpen(true)}>
				Add Network
			</Button>

			<Modal
				confirmLoading={isLoading}
				open={open}
				onCancel={() => setOpen(false)}
				title="Add Blockchain Network"
				footer={null}
			>
				<form onSubmit={formik.handleSubmit}>
					<Space style={{ width: '100%' }} direction="vertical" size={20}>
						<div>
							<p>Name</p>
							<Input
								value={formik.values.name}
								name="name"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Status</p>
							<Select
								style={{ width: '100%' }}
								onChange={(e) => formik.setFieldValue('status', e)}
								options={[
									{ label: 'Active', value: 'active' },
									{ label: 'Pending', value: 'pending' },
									{ label: 'Deleted', value: 'deleted' },
									{ label: 'Partial', value: 'partial' },
								]}
							/>
						</div>
						<div>
							<p>Abbreviation</p>
							<Input
								value={formik.values.abbreviation}
								name="abbreviation"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Explorer Url</p>
							<Input.TextArea
								value={formik.values.explorerUrl}
								name="explorerUrl"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Icon Url</p>
							<Input.TextArea
								value={formik.values.iconUrl}
								name="iconUrl"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Network ID</p>
							<Input
								value={formik.values.networkId}
								name="networkId"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Native Token Symbol</p>
							<Input
								value={formik.values.nativeTokenSymbol}
								name="nativeTokenSymbol"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Native Token CoinGecko ID</p>
							<Input
								value={formik.values.nativeTokenCoinGeckoId}
								name="nativeTokenCoinGeckoId"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Token Standard</p>
							<Input
								value={formik.values.tokenStandard}
								name="tokenStandard"
								onChange={formik.handleChange}
								placeholder="BEP20"
							/>
						</div>
						<div>
							<p>SwitchWallet Enum</p>
							<Input
								value={formik.values.switchWalletEnum}
								name="switchWalletEnum"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Allow Withdrawal</p>
							<Switch
								checked={formik.values.allowWithdrawal}
								onChange={(e) => formik.setFieldValue('allowWithdrawal', e)}
							/>
						</div>
						<div>
							<Button htmlType="submit" type="primary" block size="large">
								Submit
							</Button>
						</div>
					</Space>
				</form>
			</Modal>
		</>
	);
};

const DeleteBlockchainNetwork = ({ id }: { id: any }) => {
	const { mutate: deleteNetworks, isLoading } = useMutation(
		deleteBlockchainNetwork
	);

	return (
		<Popconfirm
			title="Delete blockchain network"
			onConfirm={() => deleteNetworks(id)}
		>
			<Button danger loading={isLoading}>
				Delete
			</Button>
		</Popconfirm>
	);
};

const EditBlockchainNetwork = ({ network }: { network: any }) => {
	const [open, setOpen] = useState(false);

	const { mutate: putNetworks, isLoading } = useMutation(putBlockchainNetwork, {
		onSuccess: () => {
			message.success('Blockchain Network updated!');
			setOpen(false);
		},
	});

	const formik = useFormik({
		initialValues: {
			name: '',
			status: '',
			explorerUrl: '',
			iconUrl: '',
			abbreviation: '',
			networkId: '',
			nativeTokenSymbol: '',
			nativeTokenCoinGeckoId: '',
			tokenStandard: '',
			switchWalletEnum: '',
			allowWithdrawal: false,
		},
		onSubmit: (values) => {
			putNetworks({ id: network?._id, data: values });
		},
	});

	useEffect(() => {
		const initProps = Object.keys(formik.values);
		initProps.forEach((prop) => {
			// if (prop === 'currencyId') {
			// 	formik.setFieldValue('currencyId', currency._id);
			// } else {
			// 	formik.setFieldValue(prop, network[prop]);
			// }
			formik.setFieldValue(prop, network[prop]);
		});
	}, [network]);

	return (
		<>
			<Button onClick={() => setOpen(true)}>
				<EllipsisOutlined />
			</Button>

			<Modal
				confirmLoading={isLoading}
				open={open}
				onCancel={() => setOpen(false)}
				title="Edit Blockchain Network"
				footer={null}
			>
				<form onSubmit={formik.handleSubmit}>
					<Space style={{ width: '100%' }} direction="vertical" size={20}>
						<div>
							<p>Name</p>
							<Input
								value={formik.values.name}
								name="name"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Status</p>
							<Select
								value={formik.values.status}
								style={{ width: '100%' }}
								onChange={(e) => formik.setFieldValue('status', e)}
								options={[
									{ label: 'Active', value: 'active' },
									{ label: 'Pending', value: 'pending' },
									{ label: 'Deleted', value: 'deleted' },
									{ label: 'Partial', value: 'partial' },
								]}
							/>
						</div>
						<div>
							<p>Abbreviation</p>
							<Input
								value={formik.values.abbreviation}
								name="abbreviation"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Explorer Url</p>
							<Input.TextArea
								value={formik.values.explorerUrl}
								name="explorerUrl"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Icon Url</p>
							<Input.TextArea
								value={formik.values.iconUrl}
								name="iconUrl"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Network ID</p>
							<Input
								value={formik.values.networkId}
								name="networkId"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Native Token Symbol</p>
							<Input
								value={formik.values.nativeTokenSymbol}
								name="nativeTokenSymbol"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Native Token CoinGecko ID</p>
							<Input
								value={formik.values.nativeTokenCoinGeckoId}
								name="nativeTokenCoinGeckoId"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Token Standard</p>
							<Input
								value={formik.values.tokenStandard}
								name="tokenStandard"
								onChange={formik.handleChange}
								placeholder="BEP20"
							/>
						</div>
						<div>
							<p>SwitchWallet Enum</p>
							<Input
								value={formik.values.switchWalletEnum}
								name="switchWalletEnum"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Allow Withdrawal</p>
							<Switch
								checked={formik.values.allowWithdrawal}
								onChange={(e) => formik.setFieldValue('allowWithdrawal', e)}
							/>
						</div>
						<div>
							<Button htmlType="submit" type="primary" block size="large">
								Submit
							</Button>
						</div>
					</Space>
				</form>
			</Modal>
		</>
	);
};
