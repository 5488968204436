import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import moment from 'moment';
import cryptojs from 'crypto-js';
import trimObject from '../utils/trim-objects';
import { retrieveToken } from '../utils/manage-auth-token';

const base = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_BASE,
});

const customBaseQuery = async (args: any, api: any, extraOptions: any) => {
	let { params, body } = args;

	params = trimObject(params);
	body = trimObject(body);

	const timestamp = moment().valueOf().toString();

	let jwt = null;
	let accessSecret = null;
	const token = retrieveToken();
	if (token) {
		jwt = token.jwt;
		accessSecret = token.accessSecret;
	}

	const payload = new URLSearchParams({
		...params,
		...body,
		timestamp,
		'api-key': jwt,
		'api-secret': accessSecret,
	}).toString();

	const signature = cryptojs
		.HmacSHA256(payload, (accessSecret as string) || '')
		.toString(cryptojs.enc.Hex);

	const headers = {
		...extraOptions?.headers,
		// ...getLocation(),
		// ...getDeviceDetails(),
		timestamp,
		authorization: 'Basic parole',
		authentication: `Basic ${jwt}`,
		apisignature: signature,
	};

	const response = await base({ ...args, headers }, api, extraOptions);
	// console.log({ response });
	const errorData: any = response.error?.data;
	if (
		response.error &&
		response.error.status === 401 &&
		errorData.error_code === 'app_002'
	) {
		// deleteToken();
		// window.history.pushState({}, '', '/auth/login');
		// window.history.go(0);
	}
	return response;
};

const hammerApi = createApi({
	reducerPath: 'hammer-api',
	baseQuery: customBaseQuery,
	endpoints: () => ({}),
});

export default hammerApi;
