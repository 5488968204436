import { Divider } from 'antd';
import PageWrapper from '../layouts/PageWrapper';
import { useRetrieveAuditTrailsQuery } from '../../services/endpoints/audit-trail';

const AuditTrail = () => {
	const { data: auditTrails } = useRetrieveAuditTrailsQuery({});

	console.log(auditTrails);
	return (
		<PageWrapper title="Audit Trail">
			<h1>Audit Trail</h1>
			<Divider />
		</PageWrapper>
	);
};

export default AuditTrail;
