import hammerApi from '../api';
import { PaginatedResponse, ResponseStructure } from './response-interfaces';

const teamService = hammerApi.injectEndpoints({
	endpoints: (build) => ({
		addTeamMember: build.mutation<ResponseStructure, { userEmail: any }>({
			query: (body) => ({
				url: '/administrator/create-admin',
				method: 'POST',
				body,
			}),
		}),
		resendActivationEmail: build.mutation<unknown, { userEmail: any }>({
			query: (body) => ({
				url: '/administrator/resend-activation-email',
				method: 'POST',
				body,
			}),
		}),
		removeTeamMember: build.mutation<
			unknown,
			{ adminId: any; type: 'revoke' | 'unrevoke'; revokeReason: any }
		>({
			query: (body) => ({
				url: '/administrator/access',
				method: 'PUT',
				body,
			}),
		}),
		addTeamMemberRole: build.mutation<
			ResponseStructure,
			{ email: any; roleId: any }
		>({
			query: (body) => ({
				url: '/administrator/team/role',
				method: 'POST',
				body,
			}),
		}),
		getTeamMembers: build.query<PaginatedResponse, unknown>({
			query: () => ({
				url: '/administrator/admin',
			}),
		}),
		getAdminRoles: build.query<ResponseStructure, unknown>({
			query: () => ({
				url: '/administrator/roles',
			}),
		}),
		createRole: build.mutation({
			query: (body) => ({
				url: '/administrator/roles',
				method: 'POST',
				body,
			}),
		}),
		createPermission: build.mutation({
			query: (body) => ({
				url: '/administrator/permissions',
				method: 'POST',
				body,
			}),
		}),
		getPermissions: build.query<PaginatedResponse, unknown>({
			query: () => ({
				url: '/administrator/permissions',
			}),
		}),
		deletePermission: build.mutation<ResponseStructure, { id: any }>({
			query: (body) => ({
				url: '/administrator/permissions',
				method: 'DELETE',
				body,
			}),
		}),
	}),
});

export const {
	useAddTeamMemberMutation,
	useResendActivationEmailMutation,
	useRemoveTeamMemberMutation,
	useGetTeamMembersQuery,
	useAddTeamMemberRoleMutation,
	useGetAdminRolesQuery,
	useGetPermissionsQuery,
	useCreatePermissionMutation,
	useDeletePermissionMutation,
	useCreateRoleMutation,
} = teamService;

export default teamService;
