import SingleMerchantEmailConfiguration from './SingleMerchantEmailConfiguration';

const MerchantEmailAnalytics = ({ merchant }: { merchant: any }) => {
	// const { data: emailAnalytics } = useGetMerchantEmailAnalyticsQuery(
	// 	{ merchantEmail: merchant?.merchantUserEmail },
	// 	{ skip: !merchant?.merchantUserEmail }
	// );

	return (
		<div>
			<SingleMerchantEmailConfiguration merchant={merchant} />
		</div>
	);
};

export default MerchantEmailAnalytics;
