import { RouteObject } from 'react-router-dom';
import CreateEmailTemplate from '../components/email-templates/CreateEmailTemplate';
import SystemEmailTemplates from '../components/email-templates';

const emailTemplateRoutes: RouteObject[] = [
	{
		path: '',
		element: <SystemEmailTemplates />,
	},
	{
		path: 'new',
		element: <CreateEmailTemplate />,
	},
];

export default emailTemplateRoutes;
