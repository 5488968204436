import { RouteObject } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import AuthProfile from '../components/profile/AuthProfile';
import CronJobs from '../components/cronjobs/CronJobs';

const dashboardRoutes: RouteObject[] = [
	{
		path: '/',
		element: <Dashboard />,
	},
	{
		path: '/profile',
		element: <AuthProfile />,
	},
	{
		path: '/cron-jobs',
		element: <CronJobs />,
	},
];

export default dashboardRoutes;
