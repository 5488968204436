import baseuri from '../baseuri';

export const getMerchantEmailDomains = async (merchantId: any) => {
	const res = await baseuri.get(
		'/administrator/merchant/xendemail/domain/' + merchantId
	);
	return res.data;
};

export const postNewDomainForAMerchant = async (data: any) => {
	const res = await baseuri.post(
		'/administrator/merchant/xendemail/domain',
		data
	);
	return res.data;
};

export const getAdminEmailProfile = async () => {
	const res = await baseuri.get('/administrator/system/xendemail/profile');
	return res.data;
};

export const deleteAdminEmailProfile = async () => {
	const res = await baseuri.delete('/administrator/system/xendemail/profile');
	return res.data;
};

export const postAdminEmailProfile = async (data: any) => {
	const res = await baseuri.post(
		'/administrator/system/xendemail/profile',
		data
	);
	return res.data;
};

export const getSystemEmailAccessKey = async () => {
	const res = await baseuri.get(
		'/administrator/system/xendemail/client-access-key'
	);
	return res.data;
};

export const postSystemEmailAccessKey = async (clientName: any) => {
	const res = await baseuri.post(
		'/administrator/system/xendemail/client-access-key',
		{ clientName }
	);
	return res.data;
};

export const deleteSystemEmailAccessKey = async () => {
	const res = await baseuri.delete(
		'/administrator/system/xendemail/client-access-key'
	);
	return res.data;
};

export const getSystemEmailConfig = async () => {
	const res = await baseuri.get('/administrator/system/config');
	return res.data;
};

export const getSystemEmailDomains = async () => {
	const res = await baseuri.get('/administrator/system/xendemail/domain');
	return res.data;
};

export const postSystemEmailDomain = async (data: any) => {
	const res = await baseuri.post(
		'/administrator/system/xendemail/domain',
		data
	);
	return res.data;
};

export const deleteSystemEmailDomain = async (id: any) => {
	const res = await baseuri.delete(
		`/administrator/system/xendemail/domain/${id}`
	);
	return res.data;
};

export const getEmailCategories = async () => {
	const res = await baseuri.get('/data/email-categories');
	return res.data?.data || [];
};

export const postEmailCategory = async (data: any) => {
	const res = await baseuri.post(
		'/administrator/system/email-categories',
		data
	);
	return res.data;
};

export const deleteEmailCategory = async (id: any) => {
	const res = await baseuri.delete(
		`/administrator/system/email-categories/${id}`
	);
	return res.data;
};
