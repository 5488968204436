import hammerApi from '../api';
import { PaginatedResponse, ResponseStructure } from './response-interfaces';

const merchantServices = hammerApi.injectEndpoints({
	endpoints: (build) => ({
		createMerchant: build.mutation<
			ResponseStructure,
			{
				companyName: any;
				merchantUserEmail: any;
				countryCode: any;
				domainName: any;
				companyEmail: any;
			}
		>({
			query: (body) => ({
				url: '/administrator/merchant/create/account',
				method: 'POST',
				body,
			}),
		}),
		resendMerchantActivationEmail: build.mutation<
			ResponseStructure,
			{ userEmail: any }
		>({
			query: (body) => ({
				url: '/administrator/merchant/resend-activation-email',
				method: 'POST',
				body,
			}),
		}),
		getMerchants: build.query<PaginatedResponse, unknown>({
			query: () => ({
				url: '/administrator/merchant',
			}),
		}),
		getSingleMerchant: build.query<ResponseStructure, { merchantId: any }>({
			query: ({ merchantId }) => ({
				url: `/administrator/merchant/${merchantId}`,
			}),
		}),
		getGameCenterProfile: build.query({
			query: () => ({
				url: '/administrator/game-center/profile',
			}),
		}),
		setupEmailProfile: build.mutation<ResponseStructure, any>({
			query: (body) => ({
				url: '/administrator/merchant/xendemail/profile',
				method: 'POST',
				body,
			}),
		}),
		setupEmailDomain: build.mutation<ResponseStructure, any>({
			query: (body) => ({
				url: '/administrator/merchant/xendemail/domain',
				method: 'POST',
				body,
			}),
		}),
		getMerchantSupportedCurrencies: build.query<
			PaginatedResponse,
			{ merchantId: any }
		>({
			query: ({ merchantId }) => ({
				url: `/administrator/merchant/supported-currency`,
				params: { merchantId },
			}),
		}),
		getMerchantEmailAnalytics: build.query<
			PaginatedResponse<{
				totalBounced: 0;
				totalClicks: 0;
				totalDelivered: 0;
				totalFailed: 0;
				totalOpened: 0;
				totalUniqueClicked: 0;
				totalViews: 0;
			}>,
			{
				merchantEmail: any;
				search?: any;
				perPage?: any;
				pageId?: any;
			}
		>({
			query: ({ merchantEmail, perPage = 10, pageId = 1 }) => ({
				url: '/administrator/xendemail/emails/analytics',
				params: { pageId, merchantEmail, perPage },
			}),
		}),
		viewMerchantsDomain: build.query<ResponseStructure, { merchantId: any }>({
			query: ({ merchantId }) => ({
				url: `/administrator/merchant/xendemail/domain/${merchantId}`,
			}),
		}),
		setDomainStatus: build.mutation({
			query: (body) => ({
				url: '/administrator/merchant/xendemail/domain/status',
				method: 'PATCH',
				body,
			}),
		}),
	}),
});

export const {
	useCreateMerchantMutation,
	useResendMerchantActivationEmailMutation,
	useGetMerchantsQuery,
	useGetSingleMerchantQuery,
	useSetupEmailProfileMutation,
	useSetupEmailDomainMutation,
	useGetMerchantSupportedCurrenciesQuery,
	useGetMerchantEmailAnalyticsQuery,
	useGetGameCenterProfileQuery,
	useViewMerchantsDomainQuery,
	useSetDomainStatusMutation,
} = merchantServices;

export default merchantServices;
