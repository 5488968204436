import {
	Button,
	Divider,
	Input,
	Modal,
	Popconfirm,
	Select,
	notification,
} from 'antd';
import {
	patchPaymentProvider,
	useAddPaymentProviderMutation,
	useAddProviderCurrencyMutation,
	useGetCurrenciesForPaymentProviderQuery,
	useGetCurrenciesQuery,
	usePaymentProvidersQuery,
	useRemovePaymentProviderMutation,
	useRemoveProviderCurrencyMutation,
} from '../../services/endpoints/currency';
import Table from '../layouts/Table';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../services/redux/hooks';
import { useMutation } from 'react-query';

const PaymentProviders = () => {
	const { PAYMENT_PROVIDERS_IDENTIFIERS } = useAppSelector(
		(store) => store.app.global
	);
	const { data: paymentProviders } = usePaymentProvidersQuery({});
	const { data: currencies } = useGetCurrenciesQuery({});
	const [addPaymentProvider, { isLoading: addingProvider, isSuccess }] =
		useAddPaymentProviderMutation();
	const [deletePaymentProvider, { isSuccess: removedPaymentProviderSuccess }] =
		useRemovePaymentProviderMutation();

	const [open, setOpen] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: '',
			identifier: '',
			currencySymbol: '',
			supportedCurrencySymbols: '',
			iconUrl: '',
			serviceUrl: '',
			paymentInvoiceExpiryHour: '',
			depositTransactionFeePercentage: '',
			depositTransactionFeeCap: '',
			withdrawalTransactionFeePercentage: '',
			withdrawalTransactionFeeCap: '',
		},
		onSubmit: (values) => {
			const freshSupportedCurrency = values.supportedCurrencySymbols
				.toString()
				.replace(',', ' ');
			addPaymentProvider({
				...values,
				supportedCurrencySymbols: freshSupportedCurrency,
			});
		},
	});

	useEffect(() => {
		if (isSuccess) {
			notification['success']({
				message: 'Payment Provider added successfully!',
			});
			setOpen(false);
		}
		if (removedPaymentProviderSuccess) {
			notification['success']({
				message: 'Removed Payment Provider!',
			});
		}
	}, [isSuccess, removedPaymentProviderSuccess]);

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<h2>Payment Providers</h2>
				<Button icon={<PlusOutlined />} onClick={() => setOpen(true)}>
					Add Provider
				</Button>
			</div>
			<Table
				dataSource={paymentProviders?.data.itemsList || []}
				fields={[
					{
						header: 'Identifier',
						accessorFn: (row) => row.identifier,
					},
					{
						header: 'Name',
						accessorFn: (row) => row.name,
					},
					{
						header: 'Currency',
						accessorFn: (row) => row.currencySymbol,
					},
					{
						header: 'Payment Methods',
						accessorFn: (row) => row.monnifyPaymentMethods,
					},
					{
						header: 'Deposit Fee',
						cell: ({ row }) => (
							<span>
								{row.original.depositTransactionFeePercentage}% (with Cap of{' '}
								{row.original.depositTransactionFeeCap})
							</span>
						),
					},
					{
						header: 'Withdrawal Fee',
						cell: ({ row }) => (
							<span>
								{row.original.withdrawalTransactionFeePercentage}% (with Cap of{' '}
								{row.original.withdrawalTransactionFeeCap})
							</span>
						),
					},
					{
						header: 'Invoice Expiry',
						cell: ({ row }) => (
							<span>{row.original.paymentInvoiceExpiryHour} hrs</span>
						),
					},
					{
						header: 'Service Url',
						accessorFn: (row) => row.serviceUrl,
					},
					{
						header: 'Updated',
						accessorFn: (row) => row.lastUpdatedAdmin?.userEmail,
					},
					{
						header: '',
						id: '_id',
						cell: ({ row }) => (
							<>
								<PaymentProvidersCurrencies
									paymentProviderId={row.original._id}
								/>
							</>
						),
					},
					{
						header: '',
						id: '_id',
						cell: ({ row }) => (
							<EditPaymentProviders paymentProvider={row.original} />
						),
					},
					{
						header: '',
						id: '_id',
						cell: ({ row }) => (
							<>
								<Popconfirm
									onConfirm={() =>
										deletePaymentProvider({
											paymentProviderId: row.original._id,
										})
									}
									title="Are you sure?"
								>
									<DeleteOutlined />
								</Popconfirm>
							</>
						),
					},
				]}
			/>

			<Modal
				onOk={() => formik.handleSubmit()}
				confirmLoading={addingProvider}
				title="Add Payment Provider"
				open={open}
				onCancel={() => setOpen(false)}
			>
				<Divider />
				<div>
					<label htmlFor="name">Name</label>
					<Input
						name="name"
						id="name"
						size="large"
						value={formik.values.name}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="currencySymbol">Currency Symbol</label>
					<Input
						name="currencySymbol"
						id="currencySymbol"
						size="large"
						value={formik.values.currencySymbol}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="supportedCurrencySymbols">
						Supported Currency Symbols
					</label>
					<Select
						mode="multiple"
						style={{ width: '100%' }}
						size="large"
						onChange={(selected) =>
							formik.setFieldValue('supportedCurrencySymbols', selected)
						}
						// value={formik.values.supportedCurrencySymbols}
					>
						{currencies?.data.itemsList?.map((currency: any) => (
							<Select.Option key={currency._id} value={currency.symbol}>
								{currency.symbol}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<label htmlFor="identifier">Identifier</label>
					<Select
						value={formik.values.identifier}
						style={{ width: '100%' }}
						size="large"
						onChange={(identifier) =>
							formik.setFieldValue('identifier', identifier)
						}
					>
						<Select.Option value="">--</Select.Option>
						{PAYMENT_PROVIDERS_IDENTIFIERS.map((provider) => (
							<Select.Option key={provider} value={provider}>
								{provider}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<label htmlFor="paymentInvoiceExpiryHour">
						Payment Invoice Expiry Hour
					</label>
					<Input
						name="paymentInvoiceExpiryHour"
						id="paymentInvoiceExpiryHour"
						size="large"
						value={formik.values.paymentInvoiceExpiryHour}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="serviceUrl">Service Url</label>
					<Input.TextArea
						name="serviceUrl"
						id="serviceUrl"
						size="large"
						value={formik.values.serviceUrl}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="iconUrl">Icon Url</label>
					<Input.TextArea
						name="iconUrl"
						id="iconUrl"
						size="large"
						value={formik.values.iconUrl}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="depositTransactionFeePercentage">
						Deposit Transaction Fee Percentage
					</label>
					<Input
						addonAfter="%"
						name="depositTransactionFeePercentage"
						id="depositTransactionFeePercentage"
						size="large"
						value={formik.values.depositTransactionFeePercentage}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="depositTransactionFeeCap">
						Deposit Transaction Fee Cap
					</label>
					<Input
						name="depositTransactionFeeCap"
						id="depositTransactionFeeCap"
						size="large"
						value={formik.values.depositTransactionFeeCap}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="withdrawalTransactionFeePercentage">
						Withdrawal Transaction Fee Percentage
					</label>
					<Input
						addonAfter="%"
						name="withdrawalTransactionFeePercentage"
						id="withdrawalTransactionFeePercentage"
						size="large"
						value={formik.values.withdrawalTransactionFeePercentage}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="withdrawalTransactionFeeCap">
						Withdrawal Transaction Fee Cap
					</label>
					<Input
						name="withdrawalTransactionFeeCap"
						id="withdrawalTransactionFeeCap"
						size="large"
						value={formik.values.withdrawalTransactionFeeCap}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
			</Modal>
		</div>
	);
};

export default PaymentProviders;

const EditPaymentProviders = ({
	paymentProvider,
}: {
	paymentProvider: any;
}) => {
	const { PAYMENT_PROVIDERS_IDENTIFIERS } = useAppSelector(
		(store) => store.app.global
	);

	const { isLoading: editingProvider, mutate: editPaymentProvider } =
		useMutation(patchPaymentProvider, {
			onSuccess: () => {
				notification['success']({
					message: 'Payment Provider edited successfully!',
				});
				setOpen(false);
			},
		});

	const [open, setOpen] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: '',
			identifier: '',
			currencySymbol: '',
			// accessToken: '',
			depositTransactionFeeCap: '',
			depositTransactionFeePercentage: '',
			withdrawalTransactionFeeCap: '',
			withdrawalTransactionFeePercentage: '',
			serviceUrl: '',
			iconUrl: '',
			paymentInvoiceExpiryHour: '',
			status: '',
		},
		onSubmit: (values) => {
			editPaymentProvider({
				// ...values,
				supportedCurrencySymbol: values.currencySymbol,
				depositTransactionFeeCap: values.depositTransactionFeeCap,
				depositTransactionFeePercentage: values.depositTransactionFeePercentage,
				withdrawalTransactionFeeCap: values.withdrawalTransactionFeeCap,
				withdrawalTransactionFeePercentage:
					values.withdrawalTransactionFeePercentage,
				paymentInvoiceExpiryHour: values.paymentInvoiceExpiryHour,
				serviceUrl: values.serviceUrl,
				iconUrl: values.iconUrl,
				paymentProviderId: paymentProvider._id,
			});
		},
	});

	useEffect(() => {
		if (paymentProvider) {
			const {
				name,
				// accessToken,
				depositTransactionFeeCap,
				depositTransactionFeePercentage,
				withdrawalTransactionFeeCap,
				withdrawalTransactionFeePercentage,
				currencySymbol,
				serviceUrl,
				iconUrl,
				paymentInvoiceExpiryHour,
				identifier,
				status,
			} = paymentProvider;
			// formik.setFieldValue('accessToken', accessToken);
			formik.setFieldValue('name', name);
			formik.setFieldValue('currencySymbol', currencySymbol);
			formik.setFieldValue('serviceUrl', serviceUrl);
			formik.setFieldValue('identifier', identifier);
			formik.setFieldValue('iconUrl', iconUrl);
			formik.setFieldValue('status', status);
			formik.setFieldValue(
				'withdrawalTransactionFeePercentage',
				withdrawalTransactionFeePercentage
			);
			formik.setFieldValue(
				'paymentInvoiceExpiryHour',
				paymentInvoiceExpiryHour
			);
			formik.setFieldValue(
				'depositTransactionFeeCap',
				depositTransactionFeeCap
			);
			formik.setFieldValue(
				'depositTransactionFeePercentage',
				depositTransactionFeePercentage
			);
			formik.setFieldValue(
				'withdrawalTransactionFeeCap',
				withdrawalTransactionFeeCap
			);
		}
	}, [paymentProvider]);

	return (
		<>
			<Button onClick={() => setOpen(true)}>
				<EditOutlined />
			</Button>

			<Modal
				confirmLoading={editingProvider}
				onOk={() => formik.handleSubmit()}
				open={open}
				onCancel={() => setOpen(false)}
				title="Edit Payment Provider"
			>
				<Divider />
				<div>
					<label htmlFor="name">Name</label>
					<Input
						size="large"
						id="name"
						name="name"
						value={formik.values.name}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="status">Status</label>
					<Select
						value={formik.values.status}
						style={{ width: '100%' }}
						size="large"
						onChange={(status) => formik.setFieldValue('status', status)}
					>
						<Select.Option value="active">Active</Select.Option>
						<Select.Option value="suspended">Suspended</Select.Option>
						<Select.Option value="pending">Pending</Select.Option>
						<Select.Option value="deleted">Deleted</Select.Option>
					</Select>
				</div>
				<br />
				<div>
					<label htmlFor="currencySymbol">Currency Symbol</label>
					<Input
						name="currencySymbol"
						id="currencySymbol"
						size="large"
						value={formik.values.currencySymbol}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="identifier">Identifier</label>
					<Select
						value={formik.values.identifier}
						style={{ width: '100%' }}
						size="large"
						onChange={(identifier) =>
							formik.setFieldValue('identifier', identifier)
						}
					>
						<Select.Option value="">--</Select.Option>
						{PAYMENT_PROVIDERS_IDENTIFIERS.map((provider) => (
							<Select.Option key={provider} value={provider}>
								{provider}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<label htmlFor="paymentInvoiceExpiryHour">
						Payment Invoice Expiry Hour
					</label>
					<Input
						name="paymentInvoiceExpiryHour"
						id="paymentInvoiceExpiryHour"
						size="large"
						value={formik.values.paymentInvoiceExpiryHour}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="depositTransactionFeeCap">
						Deposit Transaction Fee Cap
					</label>
					<Input
						name="depositTransactionFeeCap"
						id="depositTransactionFeeCap"
						size="large"
						value={formik.values.depositTransactionFeeCap}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="depositTransactionFeePercentage">
						Deposit Transaction Fee %
					</label>
					<Input
						name="depositTransactionFeePercentage"
						id="depositTransactionFeePercentage"
						size="large"
						value={formik.values.depositTransactionFeePercentage}
						onChange={formik.handleChange}
						addonAfter="%"
					/>
				</div>
				<br />
				<div>
					<label htmlFor="withdrawalTransactionFeeCap">
						Withdrawal Transaction Cap
					</label>
					<Input
						name="withdrawalTransactionFeeCap"
						id="withdrawalTransactionFeeCap"
						size="large"
						value={formik.values.withdrawalTransactionFeeCap}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="withdrawalTransactionFeePercentage">
						Withdrawal Transaction Fee %
					</label>
					<Input
						name="withdrawalTransactionFeePercentage"
						id="withdrawalTransactionFeePercentage"
						size="large"
						value={formik.values.withdrawalTransactionFeePercentage}
						onChange={formik.handleChange}
						addonAfter="%"
					/>
				</div>
				<br />
				<div>
					<label htmlFor="serviceUrl">Service Url</label>
					<Input.TextArea
						name="serviceUrl"
						id="serviceUrl"
						size="large"
						value={formik.values.serviceUrl}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="iconUrl">Icon Url</label>
					<Input.TextArea
						name="iconUrl"
						id="iconUrl"
						size="large"
						value={formik.values.iconUrl}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
			</Modal>
		</>
	);
};

const PaymentProvidersCurrencies = ({
	paymentProviderId,
}: {
	paymentProviderId: any;
}) => {
	const [open, setOpen] = useState(false);
	const [visible, setVisible] = useState(false);
	const [currencyId, setCurrencyId] = useState('');

	const { data: currencies } = useGetCurrenciesQuery({});
	const { data: paymentProviderCurrencies } =
		useGetCurrenciesForPaymentProviderQuery(
			{ paymentProviderId },
			{ skip: !open }
		);
	const [addCurrencyToPaymentProvider, { isLoading, isSuccess }] =
		useAddProviderCurrencyMutation();
	const [deletePaymentProviderCurrency, { isSuccess: removeSuccess }] =
		useRemoveProviderCurrencyMutation();

	useEffect(() => {
		if (isSuccess) {
			notification['success']({
				message: 'Currency added!',
			});
		}
		if (removeSuccess) {
			notification['success']({
				message: 'Currency removed!',
			});
		}
	}, [isSuccess, removeSuccess]);

	return (
		<>
			<Button onClick={() => setOpen(true)}>Currencies</Button>

			<Modal
				footer={null}
				open={open}
				onCancel={() => setOpen(false)}
				title="Payment Provider Currencies"
			>
				<Divider />
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button icon={<PlusOutlined />} onClick={() => setVisible(true)}>
						Add Currency
					</Button>
				</div>
				<br />

				{paymentProviderCurrencies?.data.itemsList.map((x: any) => (
					<div
						key={x._id}
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							borderBottom: '1px solid grey',
							padding: 10,
						}}
					>
						<p>
							{x.currencyId?.name} ({x.currencyId?.symbol})
						</p>
						<Popconfirm
							title="Are you sure?"
							onConfirm={() => deletePaymentProviderCurrency({ id: x._id })}
						>
							<DeleteOutlined />
						</Popconfirm>
					</div>
				))}
			</Modal>

			<Modal
				confirmLoading={isLoading}
				width={320}
				open={visible}
				okText="Add Currency"
				onOk={() =>
					addCurrencyToPaymentProvider({ paymentProviderId, currencyId })
				}
				onCancel={() => setVisible(false)}
				title="Add Currency"
			>
				<Divider />
				<Select
					style={{ width: '100%' }}
					size="large"
					onChange={(e) => setCurrencyId(e)}
				>
					<Select.Option value="">--</Select.Option>
					{currencies?.data.itemsList.map((currency: any) => (
						<Select.Option key={currency._id} value={currency._id}>
							{currency.symbol}
						</Select.Option>
					))}
				</Select>
			</Modal>
		</>
	);
};
