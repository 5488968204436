import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Input, Modal, Select, message } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import countryCode from '../../utils/countries-iso2';
import { useCreateMerchantMutation } from '../../services/endpoints/merchant';

const CreateMerchant = () => {
	const [open, setOpen] = useState(false);

	const validate = (values: any) => {
		const errors: any = {};

		if (!values.companyEmail) {
			errors.companyEmail = 'Provide an email';
		}
		if (!values.merchantUserEmail) {
			errors.merchantUserEmail = 'Provide merchant user email';
		}

		return errors;
	};

	const [createMerchant, { isSuccess: createSuccess }] =
		useCreateMerchantMutation();

	const formik = useFormik({
		initialValues: {
			merchantUserEmail: '',
			companyName: '',
			companyEmail: '',
			countryCode: '',
			domainName: '',
		},
		validate,
		onSubmit: (values) => {
			createMerchant({
				...values,
			});
		},
	});

	useEffect(() => {
		if (createSuccess) {
			setOpen(false);
			message.success('Merchant Created!');
		}
	}, [createSuccess]);

	return (
		<>
			<Button
				type="primary"
				icon={<PlusOutlined />}
				onClick={() => setOpen(true)}
			>
				Add Merchant
			</Button>

			<Modal
				title="Create Merchant"
				open={open}
				onCancel={() => setOpen(false)}
				onOk={formik.submitForm}
			>
				<Divider />
				<form>
					<div>
						<p>Company Name</p>
						<Input
							required
							name="companyName"
							size="large"
							value={formik.values.companyName}
							onChange={formik.handleChange}
						/>
					</div>
					<br />
					<div>
						<p>Company Email</p>
						<Input
							required
							size="large"
							name="companyEmail"
							value={formik.values.companyEmail}
							onChange={formik.handleChange}
							type="email"
						/>
						{formik.errors.companyEmail && (
							<Alert message={formik.errors.companyEmail} type="error" />
						)}
					</div>
					<br />
					<div>
						<p>Merchant Email</p>
						<Input
							required
							size="large"
							name="merchantUserEmail"
							value={formik.values.merchantUserEmail}
							onChange={formik.handleChange}
							type="email"
						/>
						{formik.errors.merchantUserEmail && (
							<Alert message={formik.errors.merchantUserEmail} type="error" />
						)}
					</div>
					<br />
					<div>
						<p>Domain Name (eg: google.com)</p>
						<Input
							size="large"
							addonBefore="https://"
							value={formik.values.domainName}
							name="domainName"
							onChange={formik.handleChange}
						/>
					</div>
					<br />
					<div>
						<p>Country</p>
						<Select
							size="large"
							style={{ width: '100%' }}
							onChange={(iso2Code) =>
								formik.setFieldValue('countryCode', iso2Code)
							}
						>
							<Select.Option value="">--</Select.Option>
							{countryCode.map((country) => (
								<Select.Option key={country.code} value={country.code}>
									{country.name}
								</Select.Option>
							))}
						</Select>
					</div>
					<br />
				</form>
			</Modal>
		</>
	);
};

export default CreateMerchant;
