import { RouteObject } from 'react-router-dom';
import Merchant from '../components/merchant/Merchant';
import SingleMerchant from '../components/merchant/single/SingleMerchant';
import CreateDefaultEmailTemplate from '../components/merchant/CreateDefaultEmailTemplates';

const merchantRoutes: RouteObject[] = [
	{
		path: '',
		element: <Merchant />,
	},
	{
		path: 'create-default-template',
		element: <CreateDefaultEmailTemplate />,
	},
	{
		path: ':activeTab',
		element: <Merchant />,
	},
	{
		path: 'single/:merchantId',
		element: <SingleMerchant />,
	},
	{
		path: 'single/:merchantId/:activeTab',
		element: <SingleMerchant />,
	},
];

export default merchantRoutes;
