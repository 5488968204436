import { styled } from 'styled-components';
import PageWrapper from '../layouts/PageWrapper';
import CreateMerchant from './CreateMerchant';
import { Tabs } from 'antd';
import MerchantsOverview from './Overview';
import DefaultMerchantEmailTemplates from './DefaultMerchantEmailTemplates';
import { useNavigate, useParams } from 'react-router-dom';

const Merchant = () => {
	const params = useParams();

	const navigate = useNavigate();

	const tabItems: any[] = [
		{
			key: 'overview',
			label: 'Overview',
			children: <MerchantsOverview />,
		},
		{
			key: 'default-templates',
			label: 'Default Templates',
			children: <DefaultMerchantEmailTemplates />,
		},
	];

	const getActiveTab = () => {
		if (!Object.keys(params).includes('activeTab')) {
			return 'overview';
		}

		if (!tabItems.map((x) => x.key).includes(params.activeTab)) {
			return 'overview';
		}

		return params.activeTab;
	};

	return (
		<PageWrapper title="Merchant">
			<PageStyles>
				<div className="page-header">
					<div>
						<h1>Merchant</h1>
						<p>Manage merchants</p>
					</div>
					<CreateMerchant />
				</div>

				<Tabs
					activeKey={getActiveTab()}
					items={[...tabItems]}
					onChange={(tab) => navigate(`/merchant/${tab}`)}
				/>
			</PageStyles>
		</PageWrapper>
	);
};

export default Merchant;

const PageStyles = styled.div`
	& .page-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
`;
