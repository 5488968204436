import { styled } from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../services/redux/hooks';
import { updateTheme } from '../../services/redux/reducers/app';

const Style = styled.div`
	padding: 10px;

	& button {
		margin-right: 10px;
		border: none;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background-color: transparent;
		width: 24px;
		height: 24px;
		cursor: pointer;
		border-radius: 50%;
		border: 1.5px solid ${(p) => p.theme.text};

		& span {
			font-size: 16px;
			color: ${(p) => p.theme.text};
			font-weight: 600;
		}

		&.active {
			border-color: ${(p) => p.theme.accent};

			& span {
				color: ${(p) => p.theme.accent};
			}
		}
	}
`;

const ThemeSelector = () => {
	const dispatch = useAppDispatch();
	const { theme } = useAppSelector((store) => store.app);

	const setTheme = (value: any) => {
		dispatch(updateTheme(value));
	};

	return (
		<Style>
			<button
				onClick={() => setTheme('light')}
				className={theme === 'light' ? 'active' : ''}
			>
				<span className="material-symbols-outlined">light_mode</span>
			</button>
			<button
				onClick={() => setTheme('dark')}
				className={theme === 'dark' ? 'active' : ''}
			>
				<span className="material-symbols-outlined">dark_mode</span>
			</button>
		</Style>
	);
};

export default ThemeSelector;
