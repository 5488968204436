import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Modal, Select, Tag, notification } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import {
	useCreateShippingHandlersMutation,
	useEditShippingHandlersMutation,
	useGetShippingHandlersQuery,
} from '../../services/endpoints/market-place';
import Table from '../layouts/Table';
import moment from 'moment';

const ShippingCompanies = () => {
	const { data: shippingHandlers } = useGetShippingHandlersQuery({});
	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<h2>Shipping Handlers</h2>
				<CreateShippingHandlers />
			</div>
			<Table
				dataSource={shippingHandlers?.data.itemsList || []}
				fields={[
					{
						header: 'Company Name',
						cell: ({ row }) => (
							<div>
								<p>{row.original.companyName}</p>
								{row.original.isDefault && <Tag>Default</Tag>}
							</div>
						),
					},
					{
						header: 'Identifier',
						accessorFn: (row) => row.identifier,
					},
					{
						header: 'Email',
						accessorFn: (row) => row.email,
					},
					{
						header: 'Service Url',
						accessorFn: (row) => row.serviceUrl,
					},
					{
						header: 'Updated',
						accessorFn: (row) => moment(row.updatedAt).format('ll'),
					},
					{
						header: '',
						id: '_id',
						cell: ({ row }) => <EditShippingHandler handler={row.original} />,
					},
				]}
			/>
		</div>
	);
};

export default ShippingCompanies;

const CreateShippingHandlers = () => {
	const [addShippingHandler, { isLoading, isSuccess }] =
		useCreateShippingHandlersMutation();

	const [open, setOpen] = useState(false);

	const formik = useFormik({
		initialValues: {
			companyName: '',
			description: '',
			isDefault: false,
			serviceUrl: '',
			address: '',
			accessToken: '',
			email: '',
			identifier: '',
		},
		onSubmit: (values) => {
			addShippingHandler(values);
		},
	});

	useEffect(() => {
		if (isSuccess) {
			notification['success']({
				message: 'Success',
				description: 'Shipping handler successfully added!',
			});
			setOpen(false);
		}
	}, [isSuccess]);

	return (
		<>
			<Button
				type="primary"
				icon={<PlusOutlined />}
				onClick={() => setOpen(true)}
			>
				Add Handler
			</Button>

			<Modal
				onOk={() => formik.handleSubmit()}
				confirmLoading={isLoading}
				open={open}
				onCancel={() => setOpen(false)}
				title="Add Shipping Handler"
			>
				<Divider />
				<div>
					<label htmlFor="companyName">Company Name</label>
					<Input
						name="companyName"
						id="companyName"
						size="large"
						value={formik.values.companyName}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="email">Email</label>
					<Input
						name="email"
						id="email"
						size="large"
						value={formik.values.email}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="identifier">Identifier</label>
					<Input
						name="identifier"
						id="identifier"
						size="large"
						value={formik.values.identifier}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="description">Description</label>
					<Input.TextArea
						name="description"
						id="description"
						size="large"
						value={formik.values.description}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="serviceUrl">Service Url</label>
					<Input
						addonBefore="https://"
						name="serviceUrl"
						id="serviceUrl"
						size="large"
						value={formik.values.serviceUrl}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="address">Address</label>
					<Input.TextArea
						name="address"
						id="address"
						size="large"
						value={formik.values.address}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="accessToken">Access Token</label>
					<Input.TextArea
						name="accessToken"
						id="accessToken"
						size="large"
						value={formik.values.accessToken}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="isDefault">Set As Default</label>
					<Select
						value={formik.values.isDefault}
						onChange={(v) => formik.setFieldValue('isDefault', v)}
						size="large"
						style={{ width: '100%' }}
					>
						<Select.Option value={true}>Yes</Select.Option>
						<Select.Option value={false}>No</Select.Option>
					</Select>
				</div>
				<br />
			</Modal>
		</>
	);
};

const EditShippingHandler = ({ handler }: { handler: any }) => {
	const [editShippingHandler, { isLoading, isSuccess }] =
		useEditShippingHandlersMutation();

	const [open, setOpen] = useState(false);

	const formik = useFormik({
		initialValues: {
			companyName: '',
			description: '',
			isDefault: false,
			serviceUrl: '',
			address: '',
			accessToken: '',
			email: '',
			identifier: '',
			shippingHandlerId: '',
		},
		onSubmit: (values) => {
			editShippingHandler(values);
		},
	});

	useEffect(() => {
		if (handler) {
			const {
				companyName,
				description,
				isDefault,
				serviceUrl,
				address,
				accessToken,
				email,
				identifier,
			} = handler;
			formik.setFieldValue('shippingHandlerId', handler._id);
			formik.setFieldValue('companyName', companyName);
			formik.setFieldValue('description', description);
			formik.setFieldValue('isDefault', isDefault);
			formik.setFieldValue('serviceUrl', serviceUrl);
			formik.setFieldValue('address', address);
			formik.setFieldValue('accessToken', accessToken);
			formik.setFieldValue('email', email);
			formik.setFieldValue('identifier', identifier);
		}
	}, [handler]);

	useEffect(() => {
		if (isSuccess) {
			notification['success']({
				message: 'Success',
				description: 'Shipping handler successfully edited!',
			});
			setOpen(false);
		}
	}, [isSuccess]);

	return (
		<>
			<Button type="text" onClick={() => setOpen(true)}>
				<EditOutlined />
			</Button>

			<Modal
				onOk={() => formik.handleSubmit()}
				confirmLoading={isLoading}
				open={open}
				onCancel={() => setOpen(false)}
				title="Edit Shipping Handler"
			>
				<Divider />
				<div>
					<label htmlFor="companyName">Company Name</label>
					<Input
						name="companyName"
						id="companyName"
						size="large"
						value={formik.values.companyName}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="email">Email</label>
					<Input
						name="email"
						id="email"
						size="large"
						value={formik.values.email}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="identifier">Identifier</label>
					<Input
						name="identifier"
						id="identifier"
						size="large"
						value={formik.values.identifier}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="description">Description</label>
					<Input.TextArea
						name="description"
						id="description"
						size="large"
						value={formik.values.description}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="serviceUrl">Service Url</label>
					<Input
						addonBefore="https://"
						name="serviceUrl"
						id="serviceUrl"
						size="large"
						value={formik.values.serviceUrl}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="address">Address</label>
					<Input.TextArea
						name="address"
						id="address"
						size="large"
						value={formik.values.address}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="accessToken">Access Token</label>
					<Input.TextArea
						name="accessToken"
						id="accessToken"
						size="large"
						value={formik.values.accessToken}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="isDefault">Set As Default</label>
					<Select
						value={formik.values.isDefault}
						onChange={(v) => formik.setFieldValue('isDefault', v)}
						size="large"
						style={{ width: '100%' }}
					>
						<Select.Option value={true}>Yes</Select.Option>
						<Select.Option value={false}>No</Select.Option>
					</Select>
				</div>
				<br />
			</Modal>
		</>
	);
};
