import { createSlice } from '@reduxjs/toolkit';
import { saveSettings } from '../../../utils/manage-auth-token';
import appDataService from '../../endpoints/app-data';
import cronjobService from '../../endpoints/cron-jobs';

const app = createSlice({
	name: 'app',
	initialState: {
		theme: 'dark',
		global: {
			PAYMENT_PROVIDERS_IDENTIFIERS: [],
			SYSTEM_GAMES: [],
			SYSTEM_EMAIL_TEMPLATE: [],
			EMAIL_TEMPLATE: [],
			EMAIL_TYPE: [],
		},
		cronTime: {
			systemTime: [],
			subsystemTime: [],
		},
	},
	reducers: {
		updateTheme: (store, action) => {
			saveSettings({ theme: action.payload });
			store.theme = action.payload;
		},
	},
	extraReducers: (builder) =>
		builder
			.addMatcher(
				appDataService.endpoints.globalConfiguration.matchFulfilled,
				(store, action) => {
					store.global = action.payload?.data;
				}
			)
			.addMatcher(
				cronjobService.endpoints.cronjobTimes.matchFulfilled,
				(store, action) => {
					store.cronTime = action.payload?.data;
				}
			),
});

export const { updateTheme } = app.actions;

export default app.reducer;
