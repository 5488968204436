import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

const base = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_CRON_BASE,
});

const customBaseQuery = async (args: any, api: any, extraOptions: any) => {
	const headers = {
		...extraOptions?.headers,
		authentication: 'Basic parole',
	};

	const response = await base({ ...args, headers }, api, extraOptions);
	return response;
};

const cronApi = createApi({
	reducerPath: 'cron-api',
	baseQuery: customBaseQuery,
	endpoints: () => ({}),
});

export default cronApi;

export const cronBaseuri = axios.create({
	baseURL: process.env.REACT_APP_CRON_BASE,
	headers: {
		authentication: 'Basic parole',
	},
});
