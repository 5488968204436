import hammerApi from '../api';
import baseuri from '../baseuri';
import { PaginatedResponse, ResponseStructure } from './response-interfaces';

const currencyService = hammerApi.injectEndpoints({
	endpoints: (build) => ({
		getCurrencies: build.query<PaginatedResponse, { status?: any }>({
			query: ({ status }) => ({
				url: '/administrator/currency',
				params: { status },
			}),
		}),
		createCurrencies: build.mutation<PaginatedResponse, any>({
			query: (body) => ({
				url: '/administrator/currency',
				method: 'POST',
				body,
			}),
		}),
		editCurrency: build.mutation<PaginatedResponse, any>({
			query: (body) => ({
				url: '/administrator/currency',
				method: 'PUT',
				body,
			}),
		}),
		deleteCurrency: build.mutation<PaginatedResponse, { currencyId: any }>({
			query: ({ currencyId }) => ({
				url: `/administrator/currency?currencyId=${currencyId}`,
				method: 'DELETE',
			}),
		}),
		paymentProviders: build.query<PaginatedResponse, any>({
			query: () => ({
				url: '/administrator/payment/provider',
			}),
		}),
		addPaymentProvider: build.mutation({
			query: (body) => ({
				url: '/administrator/payment/provider',
				method: 'POST',
				body,
			}),
		}),
		editPaymentProvider: build.mutation({
			query: (body) => ({
				url: '/administrator/payment/provider',
				method: 'PUT',
				body,
			}),
		}),
		removePaymentProvider: build.mutation<unknown, { paymentProviderId: any }>({
			query: ({ paymentProviderId }) => ({
				url: `/administrator/payment/provider/${paymentProviderId}`,
				method: 'DELETE',
			}),
		}),
		addProviderCurrency: build.mutation<ResponseStructure, any>({
			query: (body) => ({
				url: '/administrator/payment/provider/currency',
				method: 'POST',
				body,
			}),
		}),
		getCurrenciesForPaymentProvider: build.query<
			PaginatedResponse,
			{ paymentProviderId: any }
		>({
			query: ({ paymentProviderId }) => ({
				url: `/administrator/payment/provider/currency/${paymentProviderId}`,
			}),
		}),
		removeProviderCurrency: build.mutation<ResponseStructure, { id: any }>({
			query: ({ id }) => ({
				url: `/administrator/payment/provider/currency/${id}`,
				method: 'DELETE',
			}),
		}),
	}),
});

export const {
	useEditCurrencyMutation,
	useDeleteCurrencyMutation,
	useGetCurrenciesQuery,
	useCreateCurrenciesMutation,
	usePaymentProvidersQuery,
	useAddPaymentProviderMutation,
	useRemovePaymentProviderMutation,
	useAddProviderCurrencyMutation,
	useEditPaymentProviderMutation,
	useGetCurrenciesForPaymentProviderQuery,
	useRemoveProviderCurrencyMutation,
} = currencyService;

export default currencyService;

export const patchPaymentProvider = async (body: any) => {
	const res = await baseuri.put('/administrator/payment/provider', body);
	return res.data;
};

export const getBlockchainNetworks = async () => {
	const res = await baseuri.get('/administrator/blockchain-network');
	return res.data;
};

export const postBlockchainNetwork = async (data: any) => {
	const res = await baseuri.post('/administrator/blockchain-network', data);
	return res.data;
};
export const putBlockchainNetwork = async ({
	id,
	data,
}: {
	id: any;
	data: any;
}) => {
	const res = await baseuri.put(`/administrator/blockchain-network/`, {
		...data,
		blockChainNetworkId: id,
	});
	return res.data;
};

export const deleteBlockchainNetwork = async (id: any) => {
	const res = await baseuri.delete(`/administrator/blockchain-network/${id}`);
	return res.data;
};

export const putBlockchainContractAddress = async (data: any) => {
	const res = await baseuri.put('/administrator/contract-addresses', data);
	return res.data;
};

export const getBlockchainContractAddresses = async () => {
	const res = await baseuri.get('/administrator/contract-addresses');
	return res.data;
};
export const deleteBlockchainContractAddress = async (id: any) => {
	const res = await baseuri.delete('/administrator/contract-addresses/' + id);
	return res.data;
};
