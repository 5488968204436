import hammerApi from '../api';

const auditTrailServices = hammerApi.injectEndpoints({
	endpoints: (build) => ({
		retrieveAuditTrails: build.query({
			query: () => ({
				url: '/administrator/system/audit-trails',
			}),
		}),
	}),
});

export const { useRetrieveAuditTrailsQuery } = auditTrailServices;

export default auditTrailServices;
