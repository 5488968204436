import {
	Button,
	Divider,
	Input,
	Modal,
	Popconfirm,
	Select,
	notification,
} from 'antd';
import Table from '../layouts/Table';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import {
	useCreateCurrenciesMutation,
	useDeleteCurrencyMutation,
	useEditCurrencyMutation,
	useGetCurrenciesQuery,
} from '../../services/endpoints/currency';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import countryCode from '../../utils/countries-iso2';

const CurrencySettings = () => {
	const { data } = useGetCurrenciesQuery({});
	const [createSupportedCurrency, { isLoading, isSuccess }] =
		useCreateCurrenciesMutation();

	const [open, setOpen] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: '',
			symbol: '',
			currencyForm: '',
			decimalPrecision: '',
			minimumWithdrawalAmount: '',
			countryCode: '',
			fiatTag: 'USD',
			iconUrl: '',
			coinGeckoId: '',
		},
		onSubmit: (values) => {
			createSupportedCurrency(values);
		},
	});

	useEffect(() => {
		if (isSuccess) {
			notification['success']({
				message: 'Success',
				description: 'Currency added successfully',
			});
			setOpen(false);
		}
	}, [isSuccess]);

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div></div>
				<Button
					type="primary"
					onClick={() => setOpen(true)}
					icon={<PlusOutlined />}
				>
					Add Currency
				</Button>

				<Modal
					onOk={() => formik.handleSubmit()}
					confirmLoading={isLoading}
					okText="Submit"
					open={open}
					onCancel={() => setOpen(false)}
					title="Add Supported Currency"
				>
					<Divider />
					<div>
						<label htmlFor="name">Name</label>
						<Input
							size="large"
							name="name"
							id="name"
							value={formik.values.name}
							onChange={formik.handleChange}
						/>
					</div>
					<br />
					<div>
						<label htmlFor="symbol">Symbol</label>
						<Input
							size="large"
							name="symbol"
							id="symbol"
							value={formik.values.symbol}
							onChange={formik.handleChange}
						/>
					</div>
					<br />
					<div>
						<label htmlFor="minimumWithdrawalAmount">
							Min. Withdrawal Amount
						</label>
						<Input
							size="large"
							name="minimumWithdrawalAmount"
							id="minimumWithdrawalAmount"
							value={formik.values.minimumWithdrawalAmount}
							onChange={formik.handleChange}
						/>
					</div>
					<br />
					<div>
						<label htmlFor="decimalPrecision">Decimal Precision</label>
						<Input
							size="large"
							name="decimalPrecision"
							id="decimalPrecision"
							value={formik.values.decimalPrecision}
							onChange={formik.handleChange}
						/>
					</div>
					<br />
					<div>
						<label htmlFor="coinGeckoId">Coin Gecko Id</label>
						<Input
							size="large"
							name="coinGeckoId"
							id="coinGeckoId"
							value={formik.values.coinGeckoId}
							onChange={formik.handleChange}
						/>
					</div>
					<br />
					<div>
						<label htmlFor="currencyForm">Currency Form</label>
						<Select
							size="large"
							value={formik.values.currencyForm}
							style={{ minWidth: 150, width: '100%' }}
							onChange={(currencyForm) =>
								formik.setFieldValue('currencyForm', currencyForm)
							}
						>
							<Select.Option value="">--</Select.Option>
							<Select.Option value="asset">Asset</Select.Option>
							<Select.Option value="crypto">Crypto</Select.Option>
							<Select.Option value="fiat">Fiat</Select.Option>
						</Select>
					</div>
					<br />
					<div>
						<label htmlFor="countryCode">Country</label>
						<Select
							size="large"
							value={formik.values.countryCode}
							style={{ minWidth: 150, width: '100%' }}
							onChange={(iso2Code) =>
								formik.setFieldValue(
									'countryCode',
									String(iso2Code).toUpperCase()
								)
							}
						>
							<Select.Option value="">--</Select.Option>
							{countryCode.map((code) => (
								<Select.Option key={code.code} value={code.code}>
									{code.name}
								</Select.Option>
							))}
						</Select>
					</div>
					<br />
					<div>
						<label htmlFor="iconUrl">Icon Url</label>
						<Input.TextArea
							id="iconUrl"
							value={formik.values.iconUrl}
							onChange={formik.handleChange}
							name="iconUrl"
						/>
					</div>
				</Modal>
			</div>
			<Table
				dataSource={data?.data.itemsList || []}
				fields={[
					{
						header: 'Name',
						cell: ({ row }) => (
							<span>
								{row.original.name} ({row.original.symbol})
							</span>
						),
					},
					{
						header: 'USD Value',
						accessorFn: (row) => row.oneUsdValue || row.usdValue,
					},
					{
						header: 'Min. Withdrawawl Amount',
						accessorFn: (row) => row.minimumWithdrawalAmount,
					},
					{
						header: 'Allow Withdrawal',
						accessorFn: (row) =>
							row.allowWithdrawal ? 'Allowed' : 'Not Allowed',
					},
					{
						header: 'Coin Gecko Id',
						accessorFn: (row) => row.coinGeckoId,
					},
					{
						header: 'Status',
						accessorFn: (row) => row.status,
					},
					{
						id: '_id',
						cell: ({ row }) => <EditCurrency currency={row.original} />,
					},
				]}
				totalItems={data?.data.paginator.itemCount}
			/>
		</div>
	);
};

export default CurrencySettings;

const EditCurrency = ({ currency }: { currency: any }) => {
	const [deleteCurrency, { isLoading: deleting, isSuccess: deleteSuccess }] =
		useDeleteCurrencyMutation();
	const [editCurrency, { isLoading: editing, isSuccess: editSuccess }] =
		useEditCurrencyMutation();

	const [open, setOpen] = useState(false);

	const formik = useFormik({
		initialValues: {
			currencyId: '',
			name: '',
			symbol: '',
			status: '',
			decimalPrecision: '',
			minimumWithdrawalAmount: '',
			iconUrl: '',
			coinGeckoId: '',
			switchWalletNumber: 0,
		},
		onSubmit: (values) => {
			editCurrency({ ...values });
		},
	});

	useEffect(() => {
		const initProps = Object.keys(formik.values);
		initProps.forEach((prop) => {
			if (prop === 'currencyId') {
				formik.setFieldValue('currencyId', currency._id);
			} else {
				formik.setFieldValue(prop, currency[prop]);
			}
		});
	}, [currency]);

	useEffect(() => {
		if (editSuccess) {
			setOpen(false);
			notification['success']({
				message: 'Currency Edited',
				description: 'Currency edited successfully',
			});
		}
		if (deleteSuccess) {
			setOpen(false);
			notification['success']({
				message: 'Currency Deleted',
				description: 'Currency deleted successfully',
			});
		}
	}, [deleteSuccess, editSuccess]);

	return (
		<div>
			<Button onClick={() => setOpen(true)}>
				<EllipsisOutlined />
			</Button>

			<Modal
				confirmLoading={editing}
				open={open}
				onOk={() => formik.handleSubmit()}
				okText="Edit Currency"
				onCancel={() => setOpen(false)}
				title="Edit Currency"
			>
				<Divider />
				<div>
					<label htmlFor="name">Name</label>
					<Input
						size="large"
						name="name"
						id="name"
						value={formik.values.name}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="symbol">Symbol</label>
					<Input
						size="large"
						name="symbol"
						id="symbol"
						value={formik.values.symbol}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="minimumWithdrawalAmount">
						Minimum Withdrawal Amount
					</label>
					<Input
						size="large"
						name="minimumWithdrawalAmount"
						id="minimumWithdrawalAmount"
						value={formik.values.minimumWithdrawalAmount}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="decimalPrecision">Decimal Precision</label>
					<Input
						size="large"
						name="decimalPrecision"
						id="decimalPrecision"
						value={formik.values.decimalPrecision}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="coinGeckoId">Coin Gecko Id</label>
					<Input
						size="large"
						name="coinGeckoId"
						id="coinGeckoId"
						value={formik.values.coinGeckoId}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				{currency?.currencyForm === 'crypto' && (
					<>
						<div>
							<label htmlFor="switchWalletNumber">SwitchWallet Number</label>
							<Input
								size="large"
								name="switchWalletNumber"
								id="switchWalletNumber"
								value={formik.values.switchWalletNumber}
								onChange={formik.handleChange}
							/>
						</div>
						<br />
					</>
				)}
				<div>
					<label htmlFor="status">Status</label>
					<Select
						size="large"
						style={{ width: '100%' }}
						value={formik.values.status}
						onChange={(status) => formik.setFieldValue('status', status)}
					>
						<Select.Option value="active">Active</Select.Option>
						<Select.Option value="deleted">Deleted</Select.Option>
					</Select>
				</div>
				<br />
				<div>
					<label htmlFor="iconUrl">Icon Url</label>
					<Input
						size="large"
						name="iconUrl"
						id="iconUrl"
						value={formik.values.iconUrl}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<Divider />
				<Popconfirm
					title="Are you sure?"
					onConfirm={() => deleteCurrency({ currencyId: currency._id })}
				>
					<Button loading={deleting} danger>
						Delete Currency
					</Button>
				</Popconfirm>
			</Modal>
		</div>
	);
};
