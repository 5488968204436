import { Outlet, useNavigate } from 'react-router-dom';
import { isAppAuthenticated } from '../../utils/manage-auth-token';
import { useEffect } from 'react';

const UnAuthenticatedLayout = () => {
	const navigate = useNavigate();

	useEffect(() => {
		if (isAppAuthenticated()) {
			navigate('/');
		}
	}, [navigate]);

	if (!isAppAuthenticated()) {
		return (
			<div>
				<Outlet />
			</div>
		);
	}

	return null;
};

export default UnAuthenticatedLayout;
