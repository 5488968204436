function isStrongPassword(password: string) {
	const minLength = 8;
	const hasUppercase = /[A-Z]/.test(password);
	const hasLowercase = /[a-z]/.test(password);
	const hasNumber = /\d/.test(password);
	const hasSpecialChar = /[!@#$%^&*()\-=_+[\]{};':"\\|,.<>/?]+/.test(password);

	const isLengthValid = password.length >= minLength;
	const hasValidUppercase = hasUppercase;
	const hasValidLowercase = hasLowercase;
	const hasValidNumber = hasNumber;
	const hasValidSpecialChar = hasSpecialChar;

	return (
		isLengthValid &&
		hasValidUppercase &&
		hasValidLowercase &&
		hasValidNumber &&
		hasValidSpecialChar
	);
}

export default isStrongPassword;
