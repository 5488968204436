const removeSpacesFromObjectValues = (item: any) => {
	const resultantObject: any = {};

	// Object.keys(item).forEach((x) => {
	// 	if (item[x]) {
	// 		resultantObject[x] = String(item[x]).trim();
	// 	}
	// });

	Object.keys(item).forEach((x) => {
		const val = item[x];

		if (val) {
			if (typeof item[x] === 'object') {
				resultantObject[x] = item[x];
			} else if (typeof item[x] === 'boolean') {
				resultantObject[x] = Boolean(String(item[x]).trim());
			} else if (typeof item[x] === 'number') {
				resultantObject[x] = item[x];
			} else {
				resultantObject[x] = item[x].trim();
			}
		}
		if (val === false) {
			resultantObject[x] = val;
		}
	});

	return resultantObject;
};

function trimObject(data: any) {
	if (!Array.isArray(data) && typeof data !== 'object') {
		return data;
	}

	if (Array.isArray(data)) {
		return data.map((dataItem) => removeSpacesFromObjectValues(dataItem));
	}

	if (typeof data === 'object') {
		return removeSpacesFromObjectValues(data);
	}

	return data;
}

export default trimObject;
