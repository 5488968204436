import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Table from '../layouts/Table';
import { useGetDefaultEmailTemplatesQuery } from '../../services/endpoints/settings';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const DefaultMerchantEmailTemplates = () => {
	const navigate = useNavigate();

	const { data: emailTemplates } = useGetDefaultEmailTemplatesQuery({});

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ flex: 1 }}>
					<h2>Default Email Templates</h2>
					<p>
						These templates are used by Merchants if they have not explicitly
						configured anyone to make use of
					</p>
				</div>
				<div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						icon={<PlusOutlined />}
						onClick={() => navigate('/merchant/create-default-template')}
					>
						Add Template
					</Button>
				</div>
			</div>

			<Table
				dataSource={emailTemplates?.data?.templates?.itemsList || []}
				fields={[
					{
						header: 'Template Key',
						accessorFn: (row) => row.templateKey,
					},
					{
						header: 'Email Type',
						accessorFn: (row) => row.emailType,
					},
					{
						header: 'Updated',
						cell: ({ row }) =>
							moment(row.original.updatedAt).format('DD MMM, YY / hh:mm a'),
					},
				]}
			/>
		</div>
	);
};

export default DefaultMerchantEmailTemplates;
