import { Tabs } from 'antd';
import PageWrapper from '../layouts/PageWrapper';
import ShippingCompanies from './ShippingCompanies';

const MarketPlace = () => {
	return (
		<PageWrapper title="Market Place Settings">
			<h1>Market Place</h1>
			<Tabs
				defaultActiveKey="1"
				items={[
					{
						key: '1',
						label: 'Shipping Handlers',
						children: <ShippingCompanies />,
					},
				]}
			/>
		</PageWrapper>
	);
};

export default MarketPlace;
