import { EllipsisOutlined } from '@ant-design/icons';
import { styled } from 'styled-components';

const InfoCard = ({ title, value }: { title: string; value: any }) => {
	return (
		<Style>
			<div className="flex">
				<p>{title}</p>
				<p>
					<EllipsisOutlined />
				</p>
			</div>
			<div className="flex">
				<p className="value">{value}</p>
			</div>
		</Style>
	);
};

export default InfoCard;

const Style = styled.div`
	display: inline-block;
	min-width: 200px;
	padding: 15px;
	background-color: ${(p) => p.theme.secondaryBackground};
	border-radius: 10px;
	margin-right: 20px;

	& .flex {
		display: flex;
		justify-content: space-between;
		& .value {
			font-weight: 700;
			font-size: 2rem;
		}
	}
`;
