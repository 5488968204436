import React, { useMemo } from 'react';
import {
	useReactTable,
	getCoreRowModel,
	flexRender,
	ColumnDef,
} from '@tanstack/react-table';
import styled from 'styled-components';
// import { Pagination } from 'antd';
// import TableSearch from './TableSearch';

interface ITable {
	fields: ColumnDef<any>[];
	dataSource: any[];
	pageSize?: number;
	totalItems?: number;
	showSearch?: boolean;
	style?: React.CSSProperties;
	NoDataPlaceHolder?: any;
	placeHolderTexts?: any;
}

const Style = styled.div`
	margin-top: 20px;
	position: relative;
	display: flex;
	flex-direction: column;

	table {
		width: 100%;
		border: 1px solid transparent;
	}

	thead tr {
		background-color: ${({ theme }) => theme.backgroundOpaque};
		width: 100%;
		white-space: nowrap;
	}

	th,
	td {
		padding: 10px;
	}

	td {
		border-bottom: 1px solid ${(p) => p.theme.border};
	}

	& .flex {
		display: flex;
		justify-content: flex-end;
	}

	& .no-data {
		text-align: center;
		opacity: 0.5;
		padding: 10px;
	}
`;

const Table = ({
	fields,
	dataSource,
	style,
	showSearch = false,
}: // totalItems,
ITable) => {
	const columns = useMemo(() => fields, [fields]);
	const data = useMemo(() => dataSource, [dataSource]);

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<Style style={{ ...style }}>
			<div className="table-wrapper">
				{showSearch && (
					<div className="search">
						{/* <TableSearch
							preGlobalFilteredRows={preGlobalFilteredRows}
							globalFilter={globalFilter}
							setGlobalFilter={setGlobalFilter}
						/> */}
					</div>
				)}
				<br />
				<table>
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th
										key={header.id}
										style={{ textAlign: 'left', fontWeight: 'bold' }}
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext()
											  )}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row) => (
							<tr key={row.id}>
								{row.getVisibleCells().map((cell) => {
									return (
										<td key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</td>
									);
								})}
							</tr>
						))}
					</tbody>
				</table>
				{table.getRowModel().rows.length === 0 && (
					<div className="no-data">Empty Table</div>
				)}
			</div>
			<br />
			{/* {data.length > 0 && <Pagination total={totalItems} />} */}
		</Style>
	);
};

export default Table;
