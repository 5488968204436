import { RouteObject } from 'react-router-dom';
import MarketPlace from '../components/marketplace/Marketplace';

const marketplaceRoutes: RouteObject[] = [
	{
		path: '',
		element: <MarketPlace />,
	},
];

export default marketplaceRoutes;
