import { Button, Divider, Tag } from 'antd';
import {
	useGetMerchantSupportedCurrenciesQuery,
	useResendMerchantActivationEmailMutation,
} from '../../../services/endpoints/merchant';
import Table from '../../layouts/Table';
// import RTPShares from './RTPShares';
import moment from 'moment';
import { styled } from 'styled-components';

const SingleMerchantOverview = ({ merchant }: { merchant: any }) => {
	const [resend] = useResendMerchantActivationEmailMutation();
	const { data: merchantSupportedCurrencies } =
		useGetMerchantSupportedCurrenciesQuery({ merchantId: merchant?.data?._id });

	return (
		<PageStyle>
			<div className="details">
				<div>
					<p>{merchant?.data?.domainName}</p>
					<p className="label">Domain name</p>
				</div>
				<div>
					<p>{merchant?.data?.companyEmail}</p>
					<p className="label">Company Email</p>
				</div>
				<div>
					<p>{merchant?.data?.merchantUserEmail}</p>
					<p className="label">Merchant User Email</p>
				</div>
				<div>
					<Tag style={{ textTransform: 'uppercase' }}>
						{merchant?.data?.status}
					</Tag>
					<p className="label">Status</p>
				</div>
				<div>
					<p>{merchant?.data?.countryCode}</p>
					<p className="label">Country</p>
				</div>
				<div>
					<p>
						{merchant?.data?.isXendEmailProfileSetup ? (
							<Tag color="green">TRUE</Tag>
						) : (
							<Tag color="red">FALSE</Tag>
						)}
					</p>
					<p className="label">Email Account Setup</p>
				</div>
				<div>
					<p>
						{merchant?.data?.isXendEmailDomainSetup ? (
							<Tag color="green">TRUE</Tag>
						) : (
							<Tag color="red">FALSE</Tag>
						)}
					</p>
					<p className="label">Email Domain Setup</p>
				</div>
				<div>
					<p>
						{merchant?.data?.isXendEmailNewsletterDomainSetup ? (
							<Tag color="green">TRUE</Tag>
						) : (
							<Tag color="red">FALSE</Tag>
						)}
					</p>
					<p className="label">Newsletter Domain Setup</p>
				</div>
				<div>
					<p>{moment(merchant?.data?.createdAt).format('lll')}</p>
					<p className="label">Created</p>
				</div>
			</div>

			{!merchant?.data?.completeProfile && (
				<Button
					onClick={() =>
						resend({ userEmail: merchant?.data?.merchantUserEmail })
					}
				>
					Resend Activation Email
				</Button>
			)}

			<Divider />

			<h2>Supported Currencies</h2>
			<Table
				dataSource={merchantSupportedCurrencies?.data.itemsList || []}
				fields={[
					{
						header: 'Symbol',
						cell: ({ row }) => row.original.currencyId?.symbol,
					},
				]}
			/>

			<Divider />

			{/*<RTPShares />*/}
		</PageStyle>
	);
};

export default SingleMerchantOverview;

const PageStyle = styled.div`
	& .details > * {
		display: inline-flex;
		flex-direction: column;
		justify-content: flex-end;
		margin-right: 20px;
		margin-bottom: 20px;

		& .label {
			font-size: 12px;
			text-transform: uppercase;
			font-style: italic;
			opacity: 0.5;
		}
	}
`;
