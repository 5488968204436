import { RouteObject } from 'react-router-dom';
import TwoFactorAuthSetup from '../components/settings/TwoFactorAuthSetup';
import Settings from '../components/settings/Settings';

const settingsRoutes: RouteObject[] = [
	{
		path: 'two-factor-authentication',
		element: <TwoFactorAuthSetup />,
	},
	{
		path: '',
		element: <Settings />,
	},
	{
		path: ':activeTab',
		element: <Settings />,
	},
];

export default settingsRoutes;
