import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Table } from 'antd';
import {
	useDeleteSystemEmailTemplatesMutation,
	useGetSystemEmailTemplatesQuery,
} from '../../services/endpoints/settings';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import EmailProfile from './EmailProfile';
import SystemAdminDomains from './SystemAdminDomains';

const SystemEmailTemplates = () => {
	const navigate = useNavigate();

	const { data: emailTemplates } = useGetSystemEmailTemplatesQuery({});
	const [deleteSysTemplate] = useDeleteSystemEmailTemplatesMutation();

	const deleteSystemTemplate = ({ id }: { id: any }) => {
		const deleteTemplate = () => {
			deleteSysTemplate(id);
		};
		return (
			<Button danger onClick={deleteTemplate}>
				Delete
			</Button>
		);
	};

	return (
		<div>
			<div>
				<h1>System Email Templates</h1>
				<p>These email templates are used by just administrators</p>
			</div>

			<Divider />

			<EmailProfile />

			<Divider />
			<SystemAdminDomains />

			<Divider />

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div>
					<p>System Templates</p>
					<p style={{ fontSize: 21 }}>
						{emailTemplates?.data?.emailCount?.emailTemplateCount}/
						{emailTemplates?.data?.emailCount?.expectedEmailTemplateCount}
					</p>
				</div>
				<Button
					icon={<PlusOutlined />}
					onClick={() => navigate('/email-templates/new')}
				>
					Add Template
				</Button>
			</div>

			<Table
				dataSource={emailTemplates?.data?.templates?.itemsList || []}
				columns={[
					{
						title: 'Template Key',
						dataIndex: 'templateKey',
					},
					{
						title: 'Email Type',
						dataIndex: 'emailType',
					},
					{
						title: 'Updated',
						dataIndex: 'updatedAt',
						render: (updatedAt) =>
							moment(updatedAt).format('DD MMM, YY / hh:mm a'),
					},
					{
						title: '',
						dataIndex: '_id',
						render: (_id) => deleteSystemTemplate({ id: _id }),
					},
				]}
			/>
		</div>
	);
};

export default SystemEmailTemplates;
