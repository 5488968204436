import hammerApi from '../api';
import { PaginatedResponse, ResponseStructure } from './response-interfaces';

const marketplaceServices = hammerApi.injectEndpoints({
	endpoints: (build) => ({
		createShippingHandlers: build.mutation<ResponseStructure, any>({
			query: (body) => ({
				url: '/administrator/market-place/shipping-handlers',
				method: 'POST',
				body,
			}),
		}),
		editShippingHandlers: build.mutation({
			query: (body) => ({
				url: '/administrator/market-place/shipping-handlers',
				method: 'PUT',
				body,
			}),
		}),
		getShippingHandlers: build.query<PaginatedResponse, any>({
			query: () => ({
				url: '/administrator/market-place/shipping-handlers',
			}),
		}),
	}),
});

export const {
	useCreateShippingHandlersMutation,
	useEditShippingHandlersMutation,
	useGetShippingHandlersQuery,
} = marketplaceServices;

export default marketplaceServices;
