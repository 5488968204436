import { createSlice } from '@reduxjs/toolkit';
import profileService from '../../endpoints/profile';

const auth = createSlice({
	name: 'auth',
	initialState: {
		user: {
			enabledLoginTwoFactor: true,
			enabledTwoFactor: false,
			userEmail: '',
			userName: '',
			_id: '',
		},
	},
	reducers: {
		updateAuthUser: (store, action) => {
			store.user = { ...store.user, ...action.payload };
		},
	},
	extraReducers: (builder) =>
		builder.addMatcher(
			profileService.endpoints.getProfile.matchFulfilled,
			(store, action) => {
				store.user = action.payload?.data;
			}
		),
});

export const { updateAuthUser } = auth.actions;

export default auth.reducer;
