import { Divider } from 'antd';
import PageWrapper from '../layouts/PageWrapper';

const Notifications = () => {
	return (
		<PageWrapper title="Notifications">
			<h1>Notifcations</h1>
			<Divider />
		</PageWrapper>
	);
};

export default Notifications;
