import { styled } from 'styled-components';
import Table from '../layouts/Table';
import {
	useAddTeamMemberMutation,
	useAddTeamMemberRoleMutation,
	useCreatePermissionMutation,
	useCreateRoleMutation,
	useDeletePermissionMutation,
	useGetAdminRolesQuery,
	useGetPermissionsQuery,
	useGetTeamMembersQuery,
	useRemoveTeamMemberMutation,
	useResendActivationEmailMutation,
} from '../../services/endpoints/admin-team';
import {
	DeleteOutlined,
	EllipsisOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import {
	Button,
	Divider,
	Input,
	Modal,
	Popconfirm,
	Select,
	notification,
} from 'antd';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';

const AddTeam = () => {
	const [submitTeamMember, { isLoading, isSuccess }] =
		useAddTeamMemberMutation();

	const [open, setOpen] = useState(false);

	const formik = useFormik({
		initialValues: {
			userEmail: '',
		},
		onSubmit: (values) => {
			submitTeamMember(values);
		},
	});

	useEffect(() => {
		if (isSuccess) {
			setOpen(false);
			notification['success']({
				message: 'Team member added',
				description:
					'You can assign a role to team member after they complete signup process',
			});
		}
	}, [isSuccess]);

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					type="primary"
					icon={<PlusOutlined />}
					onClick={() => setOpen(true)}
				>
					Add Team
				</Button>
			</div>

			<Modal
				confirmLoading={isLoading}
				open={open}
				onCancel={() => setOpen(false)}
				footer={[
					<Popconfirm
						key="1"
						title="Are you sure?"
						onConfirm={formik.submitForm}
					>
						<Button type="primary">Add Team</Button>
					</Popconfirm>,
				]}
			>
				<p>Add Member</p>
				<Divider />
				<div>
					<label htmlFor="email">Email</label>
					<Input
						name="userEmail"
						size="large"
						onChange={formik.handleChange}
						id="email"
						value={formik.values.userEmail}
					/>
				</div>
			</Modal>
		</>
	);
};

const AssignRoleToTeamMember = ({ admin }: { admin: any }) => {
	const { data: adminRoles } = useGetAdminRolesQuery({});
	const [assignTeamMemberRole] = useAddTeamMemberRoleMutation();

	const [open, setOpen] = useState(false);

	const formik = useFormik({
		initialValues: {
			email: '',
			roleId: '',
		},
		onSubmit: (values) => {
			console.log(values);
			assignTeamMemberRole(values);
		},
	});

	useEffect(() => {
		if (admin) {
			formik.setFieldValue('email', admin.userEmail);
		}
	}, [admin]);

	return (
		<div>
			<Button size="small" onClick={() => setOpen(true)}>
				Assign Role
			</Button>

			<Modal
				title="Assign Role"
				open={open}
				onCancel={() => setOpen(false)}
				onOk={() => formik.handleSubmit()}
			>
				<Divider />
				<div>
					<label htmlFor="">Email</label>
					<Input size="large" value={formik.values.email} readOnly />
				</div>
				<br />
				<div>
					<label htmlFor="">Role</label>
					<Select
						style={{ width: '100%' }}
						size="large"
						value={formik.values.roleId}
						onChange={(roleId) => formik.setFieldValue('roleId', roleId)}
					>
						<Select.Option value="">---</Select.Option>
						{adminRoles?.data.map((role: any) => (
							<Select.Option key={role._id} value={role._id}>
								{role.title}
							</Select.Option>
						))}
					</Select>
				</div>
			</Modal>
		</div>
	);
};

const RemoveTeamMember = ({ adminId }: { adminId: any }) => {
	const [revokeMember, { isLoading }] = useRemoveTeamMemberMutation();

	return (
		<Popconfirm
			title="Are you sure?"
			onConfirm={() =>
				revokeMember({
					adminId,
					type: 'revoke',
					revokeReason: 'removed by admin',
				})
			}
		>
			<Button danger size="small" loading={isLoading}>
				Remove
			</Button>
		</Popconfirm>
	);
};

const AdminTeam = () => {
	const { data } = useGetTeamMembersQuery({});
	const [createPermission] = useCreatePermissionMutation();
	const [deletePermission] = useDeletePermissionMutation();
	const [createRole] = useCreateRoleMutation();
	const [resendActivationEmail, { isSuccess: resendActivationEmailSuccess }] =
		useResendActivationEmailMutation();

	const { data: adminRoles } = useGetAdminRolesQuery({});
	const { data: adminPermissions } = useGetPermissionsQuery({});

	const [openRoles, setOpenRoles] = useState(false);
	const rolesFormik = useFormik({
		initialValues: {
			title: '',
			description: '',
			permissions: [],
		},
		onSubmit: (values) => {
			createRole(values);
		},
	});

	const [openPermission, setOpenPermission] = useState(false);
	const permissionFormik = useFormik({
		initialValues: {
			name: '',
		},
		onSubmit: (values) => {
			createPermission(values);
		},
	});

	const resendEmail = (userEmail: any) => {
		Modal.confirm({
			title: 'Resend Activation Email',
			content: `Resend activation email to ${userEmail}`,
			onOk: () => {
				resendActivationEmail({ userEmail });
			},
		});
	};

	useEffect(() => {
		if (resendActivationEmailSuccess) {
			notification['success']({
				message: 'Email Sent!',
			});
		}
	}, [resendActivationEmailSuccess]);

	return (
		<Style>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<h2>Admin Team</h2>
				<AddTeam />
			</div>

			<Table
				dataSource={data?.data.itemsList || []}
				fields={[
					{
						header: 'Username',
						cell: ({ row }) =>
							row.original.userName || (
								<Button
									type="link"
									onClick={() => resendEmail(row.original.userEmail)}
								>
									Resend Email
								</Button>
							),
					},
					{
						header: 'Email',
						accessorFn: (row) => row.userEmail,
					},
					{
						header: 'Role',
						cell: ({ row }) =>
							row.original.adminRole?.title || (
								<AssignRoleToTeamMember admin={row.original} />
							),
					},
					{
						header: 'Added',
						cell: ({ row }) =>
							row.original.createdAt
								? moment(row.original.createdAt).format('ll')
								: '--',
					},
					{
						id: '_id',
						cell: ({ row }) => (
							<>
								{/* <EllipsisOutlined /> */}
								<RemoveTeamMember adminId={row.original._id} />
							</>
						),
					},
				]}
				totalItems={data?.data.paginator.itemCount}
			/>
			<Divider />

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<h2>Roles</h2>
				<Button icon={<PlusOutlined />} onClick={() => setOpenRoles(true)}>
					Create Role
				</Button>
				<Modal
					confirmLoading={false}
					open={openRoles}
					title="Create Role"
					onCancel={() => setOpenRoles(false)}
					footer={[
						<Popconfirm
							key="1"
							title="Are you sure?"
							onConfirm={rolesFormik.submitForm}
						>
							<Button type="primary">Submit Role</Button>
						</Popconfirm>,
					]}
				>
					<Divider />
					<div>
						<label htmlFor="name">title</label>
						<Input
							name="name"
							size="large"
							onChange={rolesFormik.handleChange}
							id="name"
							value={rolesFormik.values.title}
						/>
					</div>
					<br />
					<div>
						<label htmlFor="name">Description</label>
						<Input.TextArea
							name="name"
							size="large"
							onChange={rolesFormik.handleChange}
							id="name"
							value={rolesFormik.values.description}
						/>
					</div>
					<br />
					<div>
						<label htmlFor="name">Permissions</label>
						{/* <Input
							name="name"
							size="large"
							onChange={rolesFormik.handleChange}
							id="name"
							value={rolesFormik.values.title}
						/> */}
					</div>
					<br />
				</Modal>
			</div>
			<Table
				dataSource={adminRoles?.data || []}
				fields={[
					{
						header: 'Title',
						accessorFn: (row) => row.title,
					},
					{
						id: '_id',
						cell: () => <EllipsisOutlined />,
					},
				]}
				totalItems={adminRoles?.data?.length}
			/>
			<Divider />

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<h2>Permissions</h2>
				<Button icon={<PlusOutlined />} onClick={() => setOpenPermission(true)}>
					Create Permission
				</Button>
			</div>
			<Table
				dataSource={adminPermissions?.data.itemsList || []}
				fields={[
					{
						header: 'Name',
						accessorFn: (row) => row.name,
					},
					{
						id: '_id',
						cell: ({ row }) => (
							<Popconfirm
								title="Are you sure?"
								onConfirm={() => deletePermission(row.original._id)}
							>
								<DeleteOutlined />
							</Popconfirm>
						),
					},
				]}
				totalItems={adminPermissions?.data.paginator.itemCount}
			/>

			<Modal
				confirmLoading={false}
				open={openPermission}
				title="Create Permission"
				onCancel={() => setOpenPermission(false)}
				footer={[
					<Popconfirm
						key="1"
						title="Are you sure?"
						onConfirm={permissionFormik.submitForm}
					>
						<Button type="primary">Submit Permission</Button>
					</Popconfirm>,
				]}
			>
				<Divider />
				<div>
					<label htmlFor="name">Name</label>
					<Input
						name="name"
						size="large"
						onChange={permissionFormik.handleChange}
						id="name"
						value={permissionFormik.values.name}
					/>
				</div>
			</Modal>
		</Style>
	);
};

export default AdminTeam;

const Style = styled.div``;
