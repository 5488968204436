import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
	Button,
	Input,
	Modal,
	Popconfirm,
	Select,
	Space,
	Table,
	message,
} from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import {
	deleteEmailCategory,
	getEmailCategories,
	postEmailCategory,
} from '../../services/endpoints/email-setup';

function EmailCategories() {
	const {
		mutate: getCategories,
		data,
		isLoading,
	} = useMutation(getEmailCategories);

	useEffect(() => {
		getCategories();
	}, []);

	return (
		<div>
			<Space style={{ width: '100%' }} direction="vertical">
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<AddEmailCategory />
				</div>
				<Table
					loading={isLoading}
					dataSource={data || []}
					columns={[
						{ title: 'Title', dataIndex: 'title' },
						{ title: 'Key', dataIndex: 'categoryKey' },
						{ title: 'Description', dataIndex: 'description' },
						{
							title: '',
							dataIndex: '_id',
							render: (id) => <DeleteCategory id={id} />,
						},
					]}
				/>
			</Space>
		</div>
	);
}

export default EmailCategories;

const AddEmailCategory = () => {
	const [open, setOpen] = useState(false);

	const { mutate: submit, isLoading } = useMutation(postEmailCategory, {
		onSuccess: () => {
			message.success('Category Added Successfully!');
		},
	});

	const formik = useFormik({
		initialValues: {
			title: '',
			description: '',
			categoryKey: '',
		},
		onSubmit: (values) => {
			submit(values);
			setOpen(false);
		},
	});

	return (
		<>
			<Button onClick={() => setOpen(true)} icon={<PlusOutlined />}>
				Add Category
			</Button>

			<Modal
				confirmLoading={isLoading}
				footer={null}
				open={open}
				onCancel={() => setOpen(false)}
				title="Add Email Cateogry"
			>
				<form onSubmit={formik.handleSubmit}>
					<Space style={{ width: '100%' }} direction="vertical">
						<div>
							<p>Title</p>
							<Input
								value={formik.values.title}
								name="title"
								onChange={formik.handleChange}
							/>
						</div>
						<div>
							<p>Description</p>
							<Input.TextArea
								value={formik.values.description}
								name="description"
								onChange={formik.handleChange}
							/>
						</div>

						<div>
							<p>Category</p>
							<Select
								value={formik.values.categoryKey}
								onChange={(e) => formik.setFieldValue('categoryKey', e)}
								options={[
									{
										label: 'TRANSACTIONAL EMAILS',
										value: 'TRANSACTIONAL_EMAILS',
									},
									{
										label: 'NEWSLETTER',
										value: 'NEWSLETTER',
									},
									{
										label: 'MARKETING',
										value: 'MARKETING',
									},
									{ label: 'PRODUCT UPDATE', value: 'PRODUCT_UPDATE' },
								]}
								style={{ width: '100%' }}
							/>
						</div>

						<div>
							<Button htmlType="submit" block type="primary">
								Submit
							</Button>
						</div>
					</Space>
				</form>
			</Modal>
		</>
	);
};

const DeleteCategory = ({ id }: { id: any }) => {
	const { mutate: deleteCategory, isLoading } = useMutation(
		deleteEmailCategory,
		{
			onSuccess: () => {
				message.success('Email Category Removed');
			},
		}
	);
	return (
		<>
			<Popconfirm onConfirm={() => deleteCategory(id)} title="Are you sure?">
				<Button icon={<DeleteOutlined />} loading={isLoading} danger>
					Remove
				</Button>
			</Popconfirm>
		</>
	);
};
