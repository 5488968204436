import hammerApi from '../api';
import { PaginatedResponse, ResponseStructure } from './response-interfaces';

const settingsService = hammerApi.injectEndpoints({
	endpoints: (build) => ({
		getDefaultEmailTemplates: build.query<PaginatedResponse, any>({
			query: () => ({
				url: '/administrator/email/default-templates',
			}),
		}),
		getSystemEmailTemplates: build.query<PaginatedResponse, any>({
			query: () => ({
				url: '/administrator/email/system-templates',
			}),
		}),
		addSystemEmailTemplates: build.mutation<PaginatedResponse, any>({
			query: (body) => ({
				url: '/administrator/email/system-templates',
				method: 'POST',
				body,
			}),
		}),
		deleteSystemEmailTemplates: build.mutation({
			query: (id) => ({
				url: `/administrator/email/system-templates/${id}`,
				method: 'DELETE',
			}),
		}),
		addDefaultEmailTemplates: build.mutation<PaginatedResponse, any>({
			query: (body) => ({
				url: '/administrator/email/default-templates',
				method: 'POST',
				body,
			}),
		}),
		getAdminCustodialUsers: build.query<PaginatedResponse, any>({
			query: () => ({
				url: '/administrator/custodial',
			}),
		}),
		addAdminCustodialUser: build.mutation({
			query: (body) => ({
				url: '/administrator/custodial',
				method: 'POST',
				body,
			}),
		}),
		deleteAdminCustodialUser: build.mutation<
			ResponseStructure,
			{ accountId: any }
		>({
			query: ({ accountId }) => ({
				url: '/administrator/custodial',
				method: 'DELETE',
				params: { accountId },
			}),
		}),
	}),
});

export const {
	useAddSystemEmailTemplatesMutation,
	useDeleteSystemEmailTemplatesMutation,
	useGetSystemEmailTemplatesQuery,
	useGetDefaultEmailTemplatesQuery,
	useAddDefaultEmailTemplatesMutation,
	useGetAdminCustodialUsersQuery,
	useAddAdminCustodialUserMutation,
	useDeleteAdminCustodialUserMutation,
} = settingsService;

export default settingsService;
