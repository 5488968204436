import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth';
import app from './app';
import hammerApi from '../../api';
import cronApi from '../../cron-api';

const reducers = combineReducers({
	app,
	auth,
	[hammerApi.reducerPath]: hammerApi.reducer,
	[cronApi.reducerPath]: cronApi.reducer,
});

export default reducers;
