export const darkMode: ITheme = {
	// background: '#121927',
	background: '#202124',
	secondaryBackground: '#121927',
	text: '#FFFFFF',
	accent: '#84c70d',
	link: '#84c70d',
	sidebarLink: '#FFFFFF',
	hover: '#333333',
	border: '#444444',
	success: '#28A745',
	warning: '#FFC107',
	error: '#DC3545',
};

export const lightMode: ITheme = {
	background: '#FFFFFF',
	secondaryBackground: '#FFFFFF',
	text: '#333333',
	accent: '#84c70d',
	link: '#84c70d',
	sidebarLink: '#000000',
	hover: '#E6E6E6',
	border: '#CCCCCC',
	success: '#28A745',
	warning: '#FFC107',
	error: '#DC3545',
};

interface ITheme {
	background: any;
	secondaryBackground: any;
	text: any;
	accent: any;
	link: any;
	sidebarLink: any;
	hover: any;
	border: any;
	success: any;
	warning: any;
	error: any;
}
