import { styled } from 'styled-components';
import PageWrapper from '../layouts/PageWrapper';
import { Button, Divider, Input, Radio, Space } from 'antd';
import { useEffect, useState } from 'react';
import {
	useEnableTwoFactorAuthMutation,
	useStartTwoFactorAuthMutation,
} from '../../services/endpoints/auth';
import { useNavigate } from 'react-router-dom';

export const authenticators = [
	{
		label: 'Google Authenticator',
		value: 'google',
	},
	{
		label: 'Authenticator',
		value: 'authenticator',
		disabled: true,
	},
];

const TwoFactorAuthSetup = () => {
	const navigate = useNavigate();

	const [code, setCode] = useState('');
	const [selectedAuthenticator, setAuthenticator] = useState('');

	const [startTwoFASetup, { data }] = useStartTwoFactorAuthMutation();
	const [endTwoFASetup, { isLoading: endTwoFALoading, isSuccess }] =
		useEnableTwoFactorAuthMutation();

	useEffect(() => {
		if (selectedAuthenticator === 'google') {
			startTwoFASetup({ secret: data?.data?.secret?.secret, code });
		}
	}, [selectedAuthenticator]);

	useEffect(() => {
		if (isSuccess) {
			navigate('/auth/login');
		}
	}, [isSuccess]);

	return (
		<PageWrapper title="2FA | Settings">
			<Style>
				<h1>Two Factor Authentication</h1>
				<p>For more security, enable authenticator app</p>
				<Divider />

				<p>Choose the Authenticator to use</p>
				<br />
				<Radio.Group
					buttonStyle="solid"
					optionType="button"
					options={authenticators}
					value={selectedAuthenticator}
					onChange={(e) => setAuthenticator(e.target.value)}
				/>
				<Divider />
				{selectedAuthenticator === 'google' && (
					<div className="google-auth">
						<div>
							<div style={{ backgroundColor: 'white', width: 'fit-content' }}>
								<img src={data?.data?.secret?.qr || ''} alt="qrcode" />
							</div>
							<br />
							<p>Use Google Authenticator app to scan this QR code.</p>
						</div>
						<div>
							<p className="secret">{data?.data?.secret?.secret || ''}</p>
							<br />
							<p>
								If you have any problem with scanning the QR code enter the code
								manually into the APP.
							</p>
							<br />
							<div>
								<Space.Compact size="large">
									<Input
										placeholder="Authenticator Code"
										value={code}
										onChange={(e) => setCode(e.target.value)}
									/>
									<Button
										disabled={code.length < 3}
										loading={endTwoFALoading}
										onClick={() =>
											endTwoFASetup({
												code,
												secret: data?.data?.secret?.secret,
											})
										}
									>
										Verify
									</Button>
								</Space.Compact>
							</div>
						</div>
					</div>
				)}
			</Style>
		</PageWrapper>
	);
};

export default TwoFactorAuthSetup;

const Style = styled.div``;
