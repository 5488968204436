import { useNavigate, useParams } from 'react-router-dom';
import { useGetSingleMerchantQuery } from '../../../services/endpoints/merchant';
import PageWrapper from '../../layouts/PageWrapper';
import { Tabs } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import SingleMerchantOverview from './SingleMerchantOverview';
import MerchantEmailAnalytics from './EmailAnalytics';

const SingleMerchant = () => {
	const params = useParams();
	const { merchantId, activeTab } = useParams();

	const { data: merchant } = useGetSingleMerchantQuery(
		{ merchantId },
		{ skip: !merchantId }
	);

	const navigate = useNavigate();

	const tabItems: any[] = [
		{
			key: 'overview',
			label: 'Overview',
			children: <SingleMerchantOverview merchant={merchant} />,
		},
		{
			key: 'email-config',
			label: 'Email Config.',
			children: <MerchantEmailAnalytics merchant={merchant?.data} />,
		},
	];

	const getActiveTab = () => {
		if (!Object.keys(params).includes('activeTab')) {
			return 'overview';
		}

		if (!tabItems.map((x) => x.key).includes(activeTab)) {
			return 'overview';
		}

		return activeTab;
	};

	return (
		<PageWrapper title={merchant?.data.companyName || ''}>
			<h1 style={{ textTransform: 'uppercase' }}>
				{merchant?.data.companyName}
			</h1>
			<a href={merchant?.data.domainName} target="_blank" rel="noreferrer">
				Domain <LinkOutlined />
			</a>
			<br />

			<Tabs
				activeKey={getActiveTab()}
				items={[...tabItems]}
				onChange={(tab) => navigate(`/merchant/single/${merchantId}/${tab}`)}
			/>
		</PageWrapper>
	);
};

export default SingleMerchant;
