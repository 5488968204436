import { Button, Divider, Input, Modal, Pagination, Select, Tag } from 'antd';
import {
	getCronjobs,
	useAddCronjobMutation,
	useCronjobTimesQuery,
	useDeleteCronjobMutation,
	useTriggerCronJobMutation,
	useUpdateCronJobStatusMutation,
} from '../../services/endpoints/cron-jobs';
import PageWrapper from '../layouts/PageWrapper';
import Table from '../layouts/Table';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useAppSelector } from '../../services/redux/hooks';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

const CronJobs = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [visible, setVisible] = useState(false);

	useCronjobTimesQuery({});

	const [data, setData] = useState({
		itemsList: [],
		paginator: {
			currentPage: 1,
			itemCount: 0,
			perPage: 20,
		},
	});

	const [addCronjob, { isLoading, isSuccess }] = useAddCronjobMutation();
	const [deleteJob] = useDeleteCronjobMutation();

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				pageId: '1',
				perPage: '20',
			});
		}
	}, []);

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			getCronjobs(queryData).then((res: any) => setData(res.data));
		}
	}, [searchParams]);

	const { systemTime, subsystemTime } = useAppSelector(
		(store) => store.app.cronTime
	);

	const formik = useFormik({
		initialValues: {
			title: '',
			url: '',
			interval: '',
			subInterval: '',
		},
		onSubmit: (values) => {
			addCronjob(values);
		},
	});

	useEffect(() => {
		if (isSuccess) {
			setVisible(false);
		}
	}, [isSuccess]);

	return (
		<PageWrapper title="Cron Jobs">
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-end',
				}}
			>
				<div>
					<h1>Cron Jobs</h1>
					<p>Manage hammer games cron jobs.</p>
				</div>
				<div>
					<Button onClick={() => setVisible(true)} type="primary">
						Add Cronjob
					</Button>
				</div>
			</div>
			<Divider />
			<Table
				dataSource={data?.itemsList || []}
				fields={[
					{
						header: 'Title',
						cell: ({ row }) => (
							<>
								<p>{row.original.title}</p>
								<div>
									<Tag>{row.original.status}</Tag>
								</div>
								<div style={{ marginTop: 5 }}>
									<UpdateCronJobStatus id={row.original._id} />
								</div>
							</>
						),
					},
					{ header: 'URL', accessorFn: (row) => row.url },
					{
						header: 'Interval',
						cell: ({ row }) => (
							<>
								<p>{row.original.interval}</p>
								<Tag>Sub: {row.original.subInterval}</Tag>
							</>
						),
					},
					{
						header: 'Last Run',
						accessorFn: (row) =>
							moment(row.lastRunAt).format('D MMM, YY - h:mm:a'),
					},
					{
						header: 'Last Response',
						accessorKey: 'lastResponseMessage',
					},
					{
						header: 'Next Run',
						accessorFn: (row) =>
							moment(row.nextRunAt).format('D MMM, YY - h:mm:a'),
					},
					{
						header: 'Created',
						accessorFn: (row) => moment(row.createdAt).format('lll'),
					},
					{
						header: '',
						id: 'actions',
						cell: ({ row }) => (
							<>
								{/* <UpdateCronJobItem id={row.original._id} /> */}
								<TriggerCronJobItem id={row.original._id} />
								<Button
									onClick={() => deleteJob({ id: row.original._id })}
									size="small"
									danger
								>
									Delete
								</Button>
							</>
						),
					},
				]}
			/>

			<Pagination
				current={data?.paginator?.currentPage || 1}
				total={data?.paginator?.itemCount}
				pageSize={data?.paginator?.perPage || 10}
			/>

			<Modal
				title="Add a Cronjob"
				open={visible}
				onOk={() => formik.handleSubmit()}
				onCancel={() => setVisible(false)}
				confirmLoading={isLoading}
			>
				<Divider />
				<div>
					<label htmlFor="title">Title</label>
					<Input
						placeholder="Title"
						value={formik.values.title}
						name="title"
						id="title"
						size="large"
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="url">URL</label>
					<Input
						placeholder="URL"
						value={formik.values.url}
						name="url"
						id="url"
						size="large"
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="interval">Interval</label>
					<Select
						value={formik.values.interval}
						style={{ width: '100%' }}
						onChange={(e) => formik.setFieldValue('interval', e)}
						size="large"
					>
						<Select.Option>--</Select.Option>
						{systemTime.map((time) => (
							<Select.Option value={time} key={time}>
								{time}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
				<div>
					<label htmlFor="interval">Sub Interval</label>
					<Select
						value={formik.values.subInterval}
						style={{ width: '100%' }}
						onChange={(e) => formik.setFieldValue('subInterval', e)}
						size="large"
					>
						<Select.Option>--</Select.Option>
						{subsystemTime.map((time) => (
							<Select.Option value={time} key={time}>
								{time}
							</Select.Option>
						))}
					</Select>
				</div>
				<br />
			</Modal>
		</PageWrapper>
	);
};

export default CronJobs;

const TriggerCronJobItem = ({ id }: { id: any }) => {
	const [triggerJob] = useTriggerCronJobMutation();

	return (
		<Button size="small" onClick={() => triggerJob({ id })}>
			Trigger
		</Button>
	);
};

// const UpdateCronJobItem = ({ id }: { id: any }) => {
// 	const [updateJobStatus] = useTriggerCronJobMutation();

// 	return (
// 		<>
// 			<Button onClick={() => updateJobStatus({ id })}>Update</Button>

// 			<Modal>
// 				<p>show details</p>
// 			</Modal>
// 		</>
// 	);
// };

const UpdateCronJobStatus = ({ id }: { id: any }) => {
	const [updateJobStatus] = useUpdateCronJobStatusMutation();

	const [visible, setVisible] = useState(false);
	const [status, setStatus] = useState('');

	return (
		<>
			<Button size="small" onClick={() => setVisible(true)}>
				Change Status
			</Button>

			<Modal
				open={visible}
				onCancel={() => setVisible(false)}
				onOk={() => updateJobStatus({ id, status })}
			>
				<Divider />
				<p>Select Status</p>
				<Select
					style={{ width: '100%' }}
					size="large"
					onChange={(e) => setStatus(e)}
				>
					<Select>--</Select>
					<Select value="paused">Paused</Select>
					<Select value="cancelled">Cancelled</Select>
					<Select value="active">Active</Select>
				</Select>
			</Modal>
		</>
	);
};
