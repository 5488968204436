import { Button } from 'antd';
import { useAppDispatch } from '../../services/redux/hooks';
import { updateAuthUser } from '../../services/redux/reducers/auth';
import { useNavigate } from 'react-router-dom';
import { deleteToken } from '../../utils/manage-auth-token';

const Logout = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const logout = () => {
		deleteToken();
		dispatch(updateAuthUser({ _id: '', userName: '', userEmail: '' }));
		navigate('/auth/login');
	};
	return (
		<Button onClick={logout} danger>
			Logout
		</Button>
	);
};

export default Logout;
