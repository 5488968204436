import { Alert, Button, Divider, Input, Select, Space, message } from 'antd';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import isStrongPassword from '../../utils/strong-password';
import {
	useCompleteSignupMutation,
	useLazyVerifyUserNameQuery,
} from '../../services/endpoints/auth';
import { useEffect } from 'react';
import countryCode from '../../utils/countries-iso2';

const AuthSetup = () => {
	const navigate = useNavigate();
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const email = params.get('email');
	const token = params.get('code');

	const [
		completeSignup,
		{
			isSuccess: signupSuccess,
			error: completeSignupError,
			isLoading: setingUp,
		},
	] = useCompleteSignupMutation();

	const validate = (values: any) => {
		const errors: any = {};

		if (!isStrongPassword(values.password)) {
			errors.password =
				'Should contain at least 8 characters, one uppercase, one lowercase, one number and one special case character';
		}

		if (values.password !== values.confirmPassword) {
			errors.confirmPassword = 'Password values do not match';
		}

		return errors;
	};

	const formik = useFormik({
		initialValues: {
			password: '',
			confirmPassword: '',
			userPhone: '',
			countryCode: '',
			userName: '',
		},
		validate,
		onSubmit: (values) => {
			const { password, userPhone, countryCode, userName } = values;
			completeSignup({
				email,
				password,
				token,
				userName,
				userPhone,
				countryCode,
			});
		},
	});

	const [verifyUsername, { isLoading: verifyingUsername, isSuccess, isError }] =
		useLazyVerifyUserNameQuery();

	useEffect(() => {
		let timer: any;
		if (formik.values.userName.length > 3) {
			clearTimeout(timer);

			timer = setTimeout(() => {
				verifyUsername({ userName: formik.values.userName });
			}, 2000);
		}
		return () => {
			clearTimeout(timer);
		};
	}, [formik.values.userName, verifyUsername]);

	useEffect(() => {
		if (signupSuccess) {
			navigate('/auth/login');
		}
	}, [signupSuccess, navigate]);

	useEffect(() => {
		const actionError: any = completeSignupError;
		if (completeSignupError) {
			message.error(actionError?.data?.message);
		}
	}, [completeSignupError]);

	return (
		<Style>
			<h1>Complete Registration</h1>
			<p>Choose a strong password to complete your setup as an admin.</p>

			<Divider />

			<form onSubmit={formik.handleSubmit}>
				<div>
					<label htmlFor="email">Email</label>
					<Input
						name="email"
						id="email"
						size="large"
						value={email as any}
						readOnly
					/>
				</div>
				<br />
				<div>
					<label htmlFor="userName">Username</label>
					<Input
						disabled={verifyingUsername}
						name="userName"
						id="userName"
						size="large"
						value={formik.values.userName}
						onChange={formik.handleChange}
					/>
				</div>
				{formik.values.userName && isSuccess && (
					<Alert message="Username available" type="success" />
				)}
				{formik.values.userName && isError && (
					<Alert message="Username not available" type="error" />
				)}
				<br />
				<div>
					<Space.Compact style={{ width: '100%' }} size="large">
						<div>
							<p>Country</p>
							<Select
								value={formik.values.countryCode}
								style={{ minWidth: 150, width: '100%' }}
								onChange={(iso2Code) =>
									formik.setFieldValue('countryCode', iso2Code)
								}
							>
								<Select.Option value="">--</Select.Option>
								{countryCode.map((code) => (
									<Select.Option key={code.code} value={code.code}>
										{code.name}
									</Select.Option>
								))}
							</Select>
						</div>
						<div style={{ width: '100%' }}>
							<label htmlFor="userPhone">Phone Number</label>
							<Input
								size="large"
								name="userPhone"
								id="userPhone"
								value={formik.values.userPhone}
								onChange={formik.handleChange}
							/>
						</div>
					</Space.Compact>
				</div>
				<br />
				<div>
					<label htmlFor="password">Password</label>
					<Input.Password
						status={formik.errors.password ? 'error' : undefined}
						size="large"
						id="password"
						name="password"
						value={formik.values.password}
						onChange={formik.handleChange}
					/>
					{formik.errors.password && (
						<Alert message={formik.errors.password} type="error" />
					)}
				</div>
				<br />
				<div>
					<label htmlFor="confirmPassword">Confirm Password</label>
					<Input.Password
						status={formik.errors.confirmPassword ? 'error' : undefined}
						size="large"
						id="confirmPassword"
						name="confirmPassword"
						value={formik.values.confirmPassword}
						onChange={formik.handleChange}
					/>
					{formik.errors.confirmPassword && (
						<Alert message={formik.errors.confirmPassword} type="error" />
					)}
				</div>
				<br />
				<div>
					<Button
						loading={setingUp}
						size="large"
						block
						type="primary"
						htmlType="submit"
						disabled={!formik.isValid || setingUp}
					>
						Complete
					</Button>
				</div>
			</form>
		</Style>
	);
};

export default AuthSetup;

const Style = styled.div`
	padding: 20px;
	margin: auto;
	width: 500px;

	& .google-auth {
		display: flex;
		font-size: 14px;
		line-height: 1.3;

		& > div:first-child {
			flex: 0.5;
		}

		& > div:last-child {
			flex: 1;
			& .secret {
				font-size: 16px;
				padding: 5px 10px;
				background-color: ${(p) => p.theme.backgroundOpaque};
				width: fit-content;
			}
		}
	}
`;
