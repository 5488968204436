import { Divider } from 'antd';
import PageWrapper from '../layouts/PageWrapper';
import { useAppSelector } from '../../services/redux/hooks';
import { styled } from 'styled-components';

const Style = styled.div`
	& .game {
		display: inline-block;
		margin-right: 20px;
		padding: 10px 20px;
		border: 1px solid ${(p) => p.theme.border};
	}
`;

const Dashboard = () => {
	const { SYSTEM_GAMES } = useAppSelector((store) => store.app.global);

	return (
		<PageWrapper title="Home">
			<Style>
				<h1>Dashboard</h1>
				<Divider />
				<h2>Available Games</h2>
				<br />
				<div>
					{SYSTEM_GAMES.map((game: any) => (
						<div className="game" key={game.key}>
							<p>{game.title}</p>
						</div>
					))}
				</div>
			</Style>
		</PageWrapper>
	);
};

export default Dashboard;
