import hammerApi from '../api';

const appDataService = hammerApi.injectEndpoints({
	endpoints: (build) => ({
		globalConfiguration: build.query({
			query: () => ({
				url: '/data/global-configurations',
			}),
		}),
	}),
});

export const { useGlobalConfigurationQuery } = appDataService;

export default appDataService;
