import {
	Button,
	Divider,
	Input,
	Modal,
	Popover,
	Radio,
	Tag,
	notification,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { EmailEditor } from 'react-email-editor';
import extractReplacementPlaceholders from '../../utils/parse-email-template';
import { useAppSelector } from '../../services/redux/hooks';
import { useFormik } from 'formik';
import { useAddSystemEmailTemplatesMutation } from '../../services/endpoints/settings';

const CreateEmailTemplate = () => {
	const [addSystemTemplate, { isLoading, isSuccess, isError, error }] =
		useAddSystemEmailTemplatesMutation();

	const formik = useFormik({
		initialValues: {
			templateKey: '',
			emailType: '',
			html: '',
		},
		onSubmit: (values) => {
			const encodeHtml = encodeURIComponent(values.html);
			console.log(encodeHtml);
			addSystemTemplate({
				html: encodeHtml,
				fileName: values.templateKey,
				emailType: values.templateKey,
				templateKey: values.templateKey,
			});
		},
	});

	useEffect(() => {
		if (isSuccess) {
			setOpen(false);
			notification['success']({
				message: 'Successfully added',
			});
		}
		if (isError) {
			const e: any = error;
			notification['error']({
				message: 'Failed to add',
				description: e.data?.message,
			});
		}
	}, [isError, isSuccess]);

	const [open, setOpen] = useState(false);
	const [template, setTemplate] = useState('');
	const emailEditorRef: any = useRef();

	const { SYSTEM_EMAIL_TEMPLATE } = useAppSelector((store) => store.app.global);

	const [providedReplacements, setProvidedReplacements] = useState<
		Array<string>
	>([]);

	const validateReplacements = () => {
		emailEditorRef.current.editor.exportHtml(({ html }: any) => {
			setProvidedReplacements(extractReplacementPlaceholders(html));
		});
	};

	const chooseTemplate = () => {
		const choosenTemplate: any = SYSTEM_EMAIL_TEMPLATE.find(
			(x: any) => x.value === template
		);
		if (choosenTemplate) {
			return choosenTemplate;
		}
		return null;
	};

	const chooseTheFirstEmailTemplate = () => {
		if (SYSTEM_EMAIL_TEMPLATE.length > 0) {
			const first: any = SYSTEM_EMAIL_TEMPLATE[0];
			setTemplate(first?.value);
		}
	};

	const completeTemplate = () => {
		formik.setFieldValue('templateKey', template);
		formik.setFieldValue('emailType', template);

		// if (template.length > 0) {
		emailEditorRef.current.editor.exportHtml(({ html }: any) => {
			const extracted = extractReplacementPlaceholders(html);
			setProvidedReplacements(extracted);

			formik.setFieldValue('html', html);
			formik.handleSubmit();
		});
		// }
	};

	useEffect(() => {
		chooseTheFirstEmailTemplate();
	}, [SYSTEM_EMAIL_TEMPLATE]);

	return (
		<>
			<h1>New Email Template</h1>
			<Divider />

			<div>
				<p>Choose Email Template Type</p>
				<br />
				<div>
					<Radio.Group
						size="middle"
						buttonStyle="solid"
						value={template}
						onChange={(e) => setTemplate(e.target.value)}
					>
						{SYSTEM_EMAIL_TEMPLATE.map((tmp: any) => (
							<Radio.Button key={tmp.value} value={tmp.value}>
								{tmp.key.replace('_', ' ')}
							</Radio.Button>
						))}
					</Radio.Group>
				</div>
				<br />
				<div>
					<div>
						{chooseTemplate()?.replacements?.map((x: any) => (
							<Tag
								key={x}
								color={providedReplacements.includes(x) ? '#84c70d' : 'default'}
							>
								{x}
							</Tag>
						))}
					</div>
					<p style={{ fontSize: 14, marginTop: 7 }}>
						Make sure the template has these replacement placeholders. For
						example you can use{' '}
						<span style={{ fontWeight: 'bold' }}>{`{{name}}`}</span> for the
						name replacement.
					</p>
					<br />
					<div>
						<Button onClick={validateReplacements}>Validate</Button>
						<Button
							style={{ marginLeft: 10 }}
							type="primary"
							onClick={completeTemplate}
						>
							Submit Template
						</Button>
					</div>
				</div>
			</div>

			<Divider />

			<EmailEditor ref={emailEditorRef} appearance={{ theme: 'dark' }} />

			<Divider />

			<Modal
				okText="Add Template"
				open={open}
				onOk={() => formik.handleSubmit()}
				confirmLoading={isLoading}
				onCancel={() => setOpen(false)}
				title="Add Default Template"
			>
				<Divider />
				<div>
					<label htmlFor="templateKey">
						Template Key{' '}
						<Popover title="">
							<InfoCircleOutlined />
						</Popover>
					</label>
					<Input
						name="templateKey"
						id="templateKey"
						size="large"
						value={formik.values.templateKey}
						onChange={formik.handleChange}
					/>
				</div>
			</Modal>
		</>
	);
};

export default CreateEmailTemplate;
