import hammerApi from '../api';
import { ICompleteSignupBody, ILoginBody } from './request-interfaces';
import { ResponseStructure } from './response-interfaces';

const authService = hammerApi.injectEndpoints({
	endpoints: (build) => ({
		completeSignup: build.mutation<ResponseStructure, ICompleteSignupBody>({
			query: (body) => ({
				url: '/hub-auth/registration/administrator/complete-signup',
				method: 'PUT',
				body,
			}),
		}),
		verifyUserName: build.query<unknown, { userName: any }>({
			query: ({ userName }) => ({
				url: '/hub-auth/registration/username',
				params: { userName },
			}),
		}),
		login: build.mutation<ResponseStructure, ILoginBody>({
			query: (body) => ({
				url: '/hub-auth/login',
				method: 'POST',
				body,
			}),
		}),
		startTwoFactorAuth: build.mutation<ResponseStructure, any>({
			query: () => ({
				url: '/hub-auth/2fa/start/enable',
				method: 'POST',
				body: {},
			}),
		}),
		enableTwoFactorAuth: build.mutation<unknown, { secret: any; code: any }>({
			query: (body) => ({
				url: '/hub-auth/2fa/create/enable',
				method: 'POST',
				body,
			}),
		}),
		twoFactorLogin: build.mutation({
			query: () => ({
				url: '/hub-auth/2fa/login/user',
				method: 'POST',
			}),
		}),
		forgotPassword: build.mutation<unknown, { email: any }>({
			query: (body) => ({
				url: '/hub-auth/password/forgot',
				method: 'POST',
				body,
			}),
		}),
		resetPassword: build.mutation<
			unknown,
			{ password: any; otp: any; email: any }
		>({
			query: (body) => ({
				url: '/hub-auth/password/reset',
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const {
	useLazyVerifyUserNameQuery,
	useCompleteSignupMutation,
	useLoginMutation,
	useStartTwoFactorAuthMutation,
	useEnableTwoFactorAuthMutation,
	useTwoFactorLoginMutation,
	useForgotPasswordMutation,
} = authService;

export default authService;
