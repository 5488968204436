import { Tabs } from 'antd';
import PageWrapper from '../layouts/PageWrapper';
import AdminTeam from './AdminTeam';
import CurrencySettings from './Currency';
import GeneralSettings from './General';
import { useNavigate, useParams } from 'react-router-dom';
import BlockchainNetworks from './BlockchainNetworks';
import EmailCategories from './EmailCategories';

const Settings = () => {
	const navigate = useNavigate();

	const params = useParams();

	const tabItems: any[] = [
		{
			key: 'general',
			label: 'General',
			children: <GeneralSettings />,
		},
		{
			key: 'team',
			label: 'Team',
			children: <AdminTeam />,
		},
		{
			key: 'currency',
			label: 'Currency',
			children: <CurrencySettings />,
		},
		{
			key: 'blockchain',
			label: 'Blockchain',
			children: <BlockchainNetworks />,
		},
		{
			key: 'email-categories',
			label: 'Email Categories',
			children: <EmailCategories />,
		},
	];

	const getActiveTab = () => {
		if (!Object.keys(params).includes('activeTab')) {
			return 'general';
		}

		if (!tabItems.map((x) => x.key).includes(params.activeTab)) {
			return 'general';
		}

		return params.activeTab;
	};

	return (
		<PageWrapper title="Settings">
			<h1>Settings</h1>
			<Tabs
				activeKey={getActiveTab()}
				items={[...tabItems]}
				onChange={(tab) => navigate(`/settings/${tab}`)}
			/>
		</PageWrapper>
	);
};

export default Settings;
