import { Button, Checkbox, Input, notification } from 'antd';
import PageWrapper from '../layouts/PageWrapper';
import { useFormik } from 'formik';
import { styled } from 'styled-components';
import { useLoginMutation } from '../../services/endpoints/auth';
import { useEffect } from 'react';
import { saveToken } from '../../utils/manage-auth-token';
import { useNavigate } from 'react-router-dom';
import { updateAuthUser } from '../../services/redux/reducers/auth';
import { useAppDispatch } from '../../services/redux/hooks';

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [notify, contextHolder] = notification.useNotification();

	const [login, { data, isSuccess, isError, error, isLoading }] =
		useLoginMutation();

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			code: '',
			hasCode: false,
		},
		onSubmit: ({ email, password, code, hasCode }) => {
			login({
				email,
				password,
				code: !hasCode ? code : undefined,
			});
		},
	});

	useEffect(() => {
		if (data && isSuccess) {
			saveToken({
				jwt: data?.data?.accessToken,
				accessSecret: data?.data?.accessSecret,
			});
			dispatch(updateAuthUser(data?.data?.user));
			navigate('/');
		}
	}, [data, isSuccess, dispatch, navigate]);

	useEffect(() => {
		if (isError && error) {
			const e: any = error;
			notify['error']({
				message: 'Login Failed!',
				description: e.data?.message,
				duration: 6,
			});
		}
	}, [isError, error, notify]);

	return (
		<PageWrapper title={'Login'}>
			{contextHolder}
			<LoginPageStyle>
				<form onSubmit={formik.handleSubmit}>
					<h1>HammerGames Admin</h1>
					<br />
					<div>
						<label htmlFor="email">Email</label>
						<Input
							size="large"
							name="email"
							id="email"
							type="email"
							onChange={formik.handleChange}
							value={formik.values.email}
						/>
					</div>
					<br />
					<div>
						<label htmlFor="password">Password</label>
						<Input.Password
							size="large"
							name="password"
							id="password"
							onChange={formik.handleChange}
							value={formik.values.password}
						/>
					</div>
					<br />
					<div>
						<label htmlFor="code">2FA Code</label>
						<Input
							value={formik.values.code}
							onChange={formik.handleChange}
							size="large"
							name="code"
							id="code"
							disabled={formik.values.hasCode}
						/>
						<Checkbox
							checked={formik.values.hasCode}
							onChange={(e) => {
								formik.setFieldValue('hasCode', e.target.checked);
							}}
						>
							I don&apos;t have 2FA code yet
						</Checkbox>
					</div>
					<br />
					<div>
						<Button
							block
							loading={isLoading}
							type="primary"
							size="large"
							htmlType="submit"
						>
							Login
						</Button>
					</div>
					{/* <br />
					<Link style={{ color: 'white' }} to="/auth/reset-password">
						Forgot your password?
					</Link> */}
				</form>
			</LoginPageStyle>
		</PageWrapper>
	);
};

export default Login;

const LoginPageStyle = styled.div`
	display: flex;
	height: 100%;
	min-height: 100vh;
	color: white;
	padding: 20px;
	align-items: center;
	background-image: url('/login-background.jpg');
	background-size: cover;

	& form {
		max-width: 400px;
		background-color: rgba(0, 0, 0, 0.5);
		padding: 20px;
		margin: auto;
		border-radius: 5px;
	}
`;
