import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { useAppSelector } from '../../services/redux/hooks';

const EnableTwoFactorNotice = () => {
	const { user } = useAppSelector((store) => store.auth);

	if (!user.enabledTwoFactor) {
		return (
			<Style>
				<p>Enable 2FA to continue using this app.</p>
				<Link to="/settings/two-factor-authentication">Enable</Link>
			</Style>
		);
	}

	return null;
};

export default EnableTwoFactorNotice;

const Style = styled.aside`
	position: absolute;
	width: 100%;
	z-index: 1;
	background-color: red;
	color: white;
	display: flex;
	padding: 5px;
`;
