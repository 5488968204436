import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Popconfirm, Select, Table, message } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import {
	deleteBlockchainContractAddress,
	getBlockchainContractAddresses,
	getBlockchainNetworks,
	putBlockchainContractAddress,
	useGetCurrenciesQuery,
} from '../../services/endpoints/currency';
import { useMutation } from 'react-query';

function ContractAddresses() {
	const { data } = useGetCurrenciesQuery({});
	const { mutate: getNetworks, data: blockchainNetworks } = useMutation(
		getBlockchainNetworks
	);
	const { mutate: retrieveContractAddresses, data: contractAddresses } =
		useMutation(getBlockchainContractAddresses);

	useEffect(() => {
		getNetworks();
		retrieveContractAddresses();
	}, []);

	const [crytoCurrencies, setCryptoCurrencies] = useState<Array<any>>([]);

	useEffect(() => {
		const currencies = data?.data?.itemsList;
		if (Array.isArray(currencies)) {
			const cryptos = currencies.filter((x) => x.currencyForm === 'crypto');
			setCryptoCurrencies(cryptos);
		}
	}, [data]);

	return (
		<div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginBottom: 20,
				}}
			>
				<p>Contract Address</p>
				<AddContractAddress
					cryptoCurrencies={crytoCurrencies}
					blockchainNetworks={blockchainNetworks?.data?.itemsList || []}
				/>
			</div>

			<Table
				columns={[
					{ title: 'Contract Address', dataIndex: 'contractAddress' },
					{
						title: 'Blockchain',
						dataIndex: 'blockchainNetworkId',
						render: (value) => value?.abbreviation,
					},
					{
						title: 'Currency',
						dataIndex: 'currencyId',
						render: (value) => value?.symbol,
					},
					{ title: 'Decimals', dataIndex: 'decimals' },
					{
						title: '',
						dataIndex: '_id',
						render: (value) => <DeleteAddress id={value} />,
					},
				]}
				dataSource={contractAddresses?.data}
			/>
		</div>
	);
}

export default ContractAddresses;

const AddContractAddress = ({
	cryptoCurrencies,
	blockchainNetworks,
}: {
	cryptoCurrencies: any[];
	blockchainNetworks: any[];
}) => {
	const { mutate: submit, isLoading } = useMutation(
		putBlockchainContractAddress,
		{
			onSuccess: () => {
				setOpen(false);
			},
		}
	);

	const [open, setOpen] = useState(false);

	const formik = useFormik({
		initialValues: {
			contractAddress: '',
			currencyId: '',
			blockchainNetworkId: '',
			decimals: 'ether',
		},
		onSubmit: (values) => {
			submit(values);
		},
	});

	return (
		<>
			<Button
				type="primary"
				onClick={() => setOpen(true)}
				icon={<PlusOutlined />}
			>
				Add
			</Button>

			<Modal
				confirmLoading={isLoading}
				footer={[
					<Popconfirm
						key={1}
						title="Are you sure?"
						onConfirm={() => formik.handleSubmit()}
					>
						<Button type="primary" size="large">
							Submit
						</Button>
					</Popconfirm>,
				]}
				open={open}
				onCancel={() => setOpen(false)}
				title="Add Contract Address"
			>
				<div>
					<p>Blockchain Address</p>
					<Input
						size="large"
						value={formik.values.contractAddress}
						name="contractAddress"
						onChange={formik.handleChange}
					/>
				</div>
				<div>
					<p>Currency</p>
					<Select
						value={formik.values.currencyId}
						style={{ width: '100%' }}
						options={cryptoCurrencies.map((x) => ({
							label: x.symbol,
							value: x._id,
						}))}
						size="large"
						onChange={(e) => formik.setFieldValue('currencyId', e)}
					/>
				</div>
				<div>
					<p>Blockchain Network</p>
					<Select
						style={{ width: '100%' }}
						value={formik.values.blockchainNetworkId}
						size="large"
						options={blockchainNetworks?.map((x) => ({
							label: x.abbreviation,
							value: x._id,
						}))}
						onChange={(e) => formik.setFieldValue('blockchainNetworkId', e)}
					/>
				</div>
				<div>
					<p>Decimals</p>
					<Input value={formik.values.decimals} size="large" />
				</div>
			</Modal>
		</>
	);
};

const DeleteAddress = ({ id }: { id: any }) => {
	const { mutate } = useMutation(deleteBlockchainContractAddress, {
		onSuccess: () => {
			message.success('Address Deleted!');
		},
	});
	return (
		<Popconfirm title="Are you sure?" onConfirm={() => mutate(id)}>
			<Button icon={<DeleteFilled />} danger type="primary">
				Delete
			</Button>
		</Popconfirm>
	);
};
