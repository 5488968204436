import { Divider } from 'antd';
import PaymentProviders from './PaymentProviders';
import CustodialUser from './CustodialUser';

const GeneralSettings = () => {
	return (
		<div>
			<PaymentProviders />
			<Divider />
			<CustodialUser />
		</div>
	);
};

export default GeneralSettings;
