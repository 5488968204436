import { Button } from 'antd';
import { useRouteError, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

const Style = styled.div`
	width: 100%;
	height: 100%;
	min-height: 100vh;
	color: ${(p) => p.theme.text};
	background-color: ${(p) => p.theme.background};
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

const ErrorPage = () => {
	const navigate = useNavigate();
	const error: any = useRouteError();

	return (
		<Style>
			<div>
				<p>It seems you&apos;re lost</p>
				<p>{error.statusText || error.message}</p>
				<br />
				<Button onClick={() => navigate(-1)}>Go Back</Button>
			</div>
		</Style>
	);
};

export default ErrorPage;
