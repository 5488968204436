import Axios from 'axios';
import { retrieveToken } from '../utils/manage-auth-token';
import cryptojs from 'crypto-js';
import moment from 'moment';
import trimObject from '../utils/trim-objects';

const baseuri = Axios.create({
	baseURL: process.env.REACT_APP_BASE,
});

baseuri.interceptors.request.use((config) => {
	const params = trimObject(config.params);
	const body = trimObject(config.data);

	const timestamp = moment().valueOf().toString();

	let jwt = null;
	let accessSecret = null;
	const token = retrieveToken();

	if (token) {
		jwt = token.jwt;
		accessSecret = token.accessSecret;
	}

	const payload = new URLSearchParams({
		...params,
		...body,
		timestamp,
		'api-key': jwt,
		'api-secret': accessSecret,
	}).toString();

	const signature = cryptojs
		.HmacSHA256(payload, (accessSecret as string) || '')
		.toString(cryptojs.enc.Hex);

	config.headers = {
		...config.headers,
		timestamp,
		authorization: 'Basic parole',
		authentication: 'Basic ' + jwt,
		apisignature: signature,
	} as any;

	return config;
});

baseuri.interceptors.response.use(
	(res) => res,
	async (error) => {
		console.log(error);
	}
);

export default baseuri;
